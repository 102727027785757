import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { createProduct, createStreamingEvent } from '../../firebase/schema';
import { motion } from 'framer-motion';
import { FaStore, FaVideo, FaChartLine, FaUsers } from 'react-icons/fa';

const Container = styled.div`
  padding: 2rem;
`;

const Header = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  color: white;
  margin-bottom: 1rem;
  font-size: 2rem;
`;

const Stats = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
`;

const StatCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StatIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4a90e2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
`;

const StatContent = styled.div``;

const StatValue = styled.div`
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
`;

const StatLabel = styled.div`
  color: #888;
  font-size: 0.9rem;
`;

const Tabs = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const Tab = styled.button`
  padding: 0.8rem 1.5rem;
  background: ${props => props.active ? '#4a90e2' : 'rgba(255, 255, 255, 0.05)'};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${props => props.active ? '#4a90e2' : 'rgba(255, 255, 255, 0.1)'};
  }
`;

const ContentArea = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const Card = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 1.5rem;
`;

const CardTitle = styled.h3`
  color: white;
  margin-bottom: 0.5rem;
`;

const CardDescription = styled.p`
  color: #888;
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;

const CardStats = styled.div`
  display: flex;
  justify-content: space-between;
  color: #4a90e2;
  font-size: 0.9rem;
`;

const ArtistDashboard = () => {
  const [activeTab, setActiveTab] = useState('products');
  const [products, setProducts] = useState([]);
  const [events, setEvents] = useState([]);
  const [stats, setStats] = useState({
    totalSales: 0,
    totalProducts: 0,
    totalEvents: 0,
    totalCustomers: 0
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch products
        const productsQuery = query(
          collection(db, 'products'),
          where('artistId', '==', 'current-user-id') // Replace with actual user ID
        );
        const productsSnapshot = await getDocs(productsQuery);
        setProducts(productsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })));

        // Fetch events
        const eventsQuery = query(
          collection(db, 'streamEvents'),
          where('artistId', '==', 'current-user-id') // Replace with actual user ID
        );
        const eventsSnapshot = await getDocs(eventsQuery);
        setEvents(eventsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })));

        // Calculate stats
        setStats({
          totalSales: productsSnapshot.docs.reduce((acc, doc) => acc + doc.data().sales, 0),
          totalProducts: productsSnapshot.size,
          totalEvents: eventsSnapshot.size,
          totalCustomers: 0 // This would need a separate query to calculate
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case 'products':
        return (
          <Grid>
            {products.map(product => (
              <Card
                key={product.id}
                whileHover={{ y: -5 }}
                transition={{ duration: 0.2 }}
              >
                <CardImage src={product.imageUrl} alt={product.title} />
                <CardContent>
                  <CardTitle>{product.title}</CardTitle>
                  <CardDescription>{product.description}</CardDescription>
                  <CardStats>
                    <span>Sales: {product.sales}</span>
                    <span>${product.price}</span>
                  </CardStats>
                </CardContent>
              </Card>
            ))}
          </Grid>
        );
      case 'events':
        return (
          <Grid>
            {events.map(event => (
              <Card
                key={event.id}
                whileHover={{ y: -5 }}
                transition={{ duration: 0.2 }}
              >
                <CardImage src={event.imageUrl} alt={event.title} />
                <CardContent>
                  <CardTitle>{event.title}</CardTitle>
                  <CardDescription>{event.description}</CardDescription>
                  <CardStats>
                    <span>Tickets: {event.ticketsSold}</span>
                    <span>${event.price}</span>
                  </CardStats>
                </CardContent>
              </Card>
            ))}
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <Header>
        <Title>Artist Dashboard</Title>
        <Stats>
          <StatCard whileHover={{ scale: 1.02 }}>
            <StatIcon>
              <FaStore />
            </StatIcon>
            <StatContent>
              <StatValue>${stats.totalSales}</StatValue>
              <StatLabel>Total Sales</StatLabel>
            </StatContent>
          </StatCard>
          <StatCard whileHover={{ scale: 1.02 }}>
            <StatIcon>
              <FaChartLine />
            </StatIcon>
            <StatContent>
              <StatValue>{stats.totalProducts}</StatValue>
              <StatLabel>Products</StatLabel>
            </StatContent>
          </StatCard>
          <StatCard whileHover={{ scale: 1.02 }}>
            <StatIcon>
              <FaVideo />
            </StatIcon>
            <StatContent>
              <StatValue>{stats.totalEvents}</StatValue>
              <StatLabel>Events</StatLabel>
            </StatContent>
          </StatCard>
          <StatCard whileHover={{ scale: 1.02 }}>
            <StatIcon>
              <FaUsers />
            </StatIcon>
            <StatContent>
              <StatValue>{stats.totalCustomers}</StatValue>
              <StatLabel>Customers</StatLabel>
            </StatContent>
          </StatCard>
        </Stats>
      </Header>

      <Tabs>
        <Tab
          active={activeTab === 'products'}
          onClick={() => setActiveTab('products')}
        >
          Products
        </Tab>
        <Tab
          active={activeTab === 'events'}
          onClick={() => setActiveTab('events')}
        >
          Events
        </Tab>
      </Tabs>

      <ContentArea>
        {renderContent()}
      </ContentArea>
    </Container>
  );
};

export default ArtistDashboard;
