import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MarketplaceLayout from './MarketplaceLayout';
import ProductList from './ProductList';
import ArtistRegistration from './ArtistRegistration';
import SignIn from '../auth/SignIn';
import SignUp from '../auth/SignUp';
import Cart from './Cart';
import ProductDetail from './ProductDetail';
import Checkout from './Checkout';
import OrderSuccess from './OrderSuccess';
import OrderHistory from './OrderHistory';
import OrderDetails from './OrderDetails';
import ArtistRequestsDashboard from '../admin/ArtistRequestsDashboard';
import { useAuth } from '../../contexts/AuthContext';
import { ROLES } from '../../firebase.marketplace';

const ProtectedRoute = ({ children, requiredRole }) => {
  const { currentUser, hasRole, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <Navigate to="/marketplace/auth/signin" />;
  }

  if (requiredRole && !hasRole(requiredRole)) {
    return <div>Access denied. Insufficient permissions.</div>;
  }

  return children;
};

const MarketplaceRoutes = () => {
  return (
    <Routes>
      {/* Auth Routes */}
      <Route path="auth/signin" element={<MarketplaceLayout><SignIn /></MarketplaceLayout>} />
      <Route path="auth/signup" element={<MarketplaceLayout><SignUp /></MarketplaceLayout>} />

      {/* Public Routes */}
      <Route path="/" element={<MarketplaceLayout><ProductList /></MarketplaceLayout>} />
      <Route path="products/:productId" element={<MarketplaceLayout><ProductDetail /></MarketplaceLayout>} />
      <Route path="artist-registration" element={<MarketplaceLayout><ArtistRegistration /></MarketplaceLayout>} />

      {/* Protected Routes */}
      <Route path="cart" element={
        <ProtectedRoute>
          <MarketplaceLayout><Cart /></MarketplaceLayout>
        </ProtectedRoute>
      } />
      <Route path="checkout" element={
        <ProtectedRoute>
          <MarketplaceLayout><Checkout /></MarketplaceLayout>
        </ProtectedRoute>
      } />
      <Route path="order-success" element={
        <ProtectedRoute>
          <MarketplaceLayout><OrderSuccess /></MarketplaceLayout>
        </ProtectedRoute>
      } />
      <Route path="orders" element={
        <ProtectedRoute>
          <MarketplaceLayout><OrderHistory /></MarketplaceLayout>
        </ProtectedRoute>
      } />
      <Route path="orders/:orderId" element={
        <ProtectedRoute>
          <MarketplaceLayout><OrderDetails /></MarketplaceLayout>
        </ProtectedRoute>
      } />

      {/* Admin Routes */}
      <Route path="admin/artist-requests" element={
        <ProtectedRoute requiredRole={ROLES.ADMIN}>
          <MarketplaceLayout><ArtistRequestsDashboard /></MarketplaceLayout>
        </ProtectedRoute>
      } />

      {/* Catch all route */}
      <Route path="*" element={<Navigate to="/marketplace" replace />} />
    </Routes>
  );
};

export default MarketplaceRoutes;
