import React from 'react';
import useAdsense from '../hooks/useAdsense';

const CompliexAd = () => {
  const adRef = useAdsense();

  return (
    <div style={{ overflow: 'hidden' }} ref={adRef}>
      <ins
        className="adsbygoogle"
        style={{ display: 'block', background: "#030617" }}
        data-ad-client="ca-pub-2968429081843062"
        data-ad-format="autorelaxed"
        data-ad-slot="7184480558"
      />
    </div>
  );
};

export default CompliexAd;