import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { createStreamingEvent } from '../../firebase/schema';
import { motion } from 'framer-motion';
import { FaImage, FaCalendar, FaClock, FaTicketAlt } from 'react-icons/fa';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Form = styled.form`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
`;

const Title = styled.h2`
  color: white;
  margin-bottom: 2rem;
  text-align: center;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  color: white;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(74, 144, 226, 0.3);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  outline: none;

  &:focus {
    border-color: #4a90e2;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(74, 144, 226, 0.3);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  min-height: 120px;
  outline: none;
  resize: vertical;

  &:focus {
    border-color: #4a90e2;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const ImagePreview = styled.div`
  width: 100%;
  height: 200px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  overflow: hidden;
`;

const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const UploadIcon = styled.div`
  color: #4a90e2;
  font-size: 3rem;
`;

const Button = styled(motion.button)`
  width: 100%;
  padding: 1rem;
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #357abd;
  }

  &:disabled {
    background: #666;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #ff3b30;
  margin-top: 0.5rem;
  font-size: 0.9rem;
`;

const CreateEventForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: '',
    time: '',
    price: '',
    maxAttendees: '',
    image: null
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, image: file });
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      // Validate form data
      if (!formData.title || !formData.description || !formData.date || 
          !formData.time || !formData.price || !formData.maxAttendees || !formData.image) {
        throw new Error('Please fill in all fields');
      }

      // Create event
      const result = await createStreamingEvent(
        'current-user-id', // Replace with actual user ID
        {
          title: formData.title,
          description: formData.description,
          date: formData.date,
          time: formData.time,
          price: parseFloat(formData.price),
          maxAttendees: parseInt(formData.maxAttendees)
        },
        formData.image
      );

      // Navigate to the event page
      navigate(`/streaming-events/${result.eventId}`);
    } catch (error) {
      console.error('Error creating event:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Title>Create New Event</Title>

        <FormGroup>
          <Label>Event Cover Image</Label>
          <ImagePreview>
            {imagePreview ? (
              <PreviewImage src={imagePreview} alt="Preview" />
            ) : (
              <UploadIcon>
                <FaImage />
              </UploadIcon>
            )}
          </ImagePreview>
          <Input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>Event Title</Label>
          <Input
            type="text"
            placeholder="Enter event title"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
        </FormGroup>

        <FormGroup>
          <Label>Description</Label>
          <TextArea
            placeholder="Enter event description"
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
        </FormGroup>

        <FormGroup>
          <Label>Date</Label>
          <Input
            type="date"
            value={formData.date}
            onChange={(e) => setFormData({ ...formData, date: e.target.value })}
          />
        </FormGroup>

        <FormGroup>
          <Label>Time</Label>
          <Input
            type="time"
            value={formData.time}
            onChange={(e) => setFormData({ ...formData, time: e.target.value })}
          />
        </FormGroup>

        <FormGroup>
          <Label>Ticket Price ($)</Label>
          <Input
            type="number"
            step="0.01"
            min="0"
            placeholder="Enter ticket price"
            value={formData.price}
            onChange={(e) => setFormData({ ...formData, price: e.target.value })}
          />
        </FormGroup>

        <FormGroup>
          <Label>Maximum Attendees</Label>
          <Input
            type="number"
            min="1"
            placeholder="Enter maximum number of attendees"
            value={formData.maxAttendees}
            onChange={(e) => setFormData({ ...formData, maxAttendees: e.target.value })}
          />
        </FormGroup>

        {error && <ErrorMessage>{error}</ErrorMessage>}

        <Button
          type="submit"
          disabled={loading}
          whileHover={{ scale: loading ? 1 : 1.02 }}
          whileTap={{ scale: loading ? 1 : 0.98 }}
        >
          {loading ? 'Creating Event...' : 'Create Event'}
        </Button>
      </Form>
    </Container>
  );
};

export default CreateEventForm;
