import React from 'react';
import useAdsense from '../hooks/useAdsense';

const GoogleAd = () => {
  const adRef = useAdsense();

  return (
    <div className="Ad" style={{ background: "#030617" }} ref={adRef}>
      <ins
        className="adsbygoogle"
        style={{ display: "block", background: "#030617" }}
        data-ad-client="ca-pub-2968429081843062"
        data-ad-slot="9697830075"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </div>
  );
};

export default GoogleAd;
