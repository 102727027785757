import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { ROLES } from '../../firebase.marketplace';

const ProtectedRoute = ({ children, requiredRole }) => {
  const { currentUser, loading, hasRole, userRoles } = useAuth();

  console.log('ProtectedRoute - Current user:', currentUser?.email);
  console.log('ProtectedRoute - Required role:', requiredRole);
  console.log('ProtectedRoute - User roles:', userRoles);
  console.log('ProtectedRoute - Has required role:', hasRole(requiredRole));

  if (loading) {
    return (
      <div style={{ 
        minHeight: '100vh', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        backgroundColor: '#030617',
        color: 'white'
      }}>
        Loading...
      </div>
    );
  }

  if (!currentUser) {
    return <Navigate to="/auth/signin" />;
  }

  if (requiredRole && !hasRole(requiredRole)) {
    // Special handling for admin access
    if (requiredRole === ROLES.ADMIN) {
      return (
        <div style={{ 
          minHeight: '100vh', 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center', 
          justifyContent: 'center',
          backgroundColor: '#030617',
          color: 'white',
          padding: '2rem',
          textAlign: 'center'
        }}>
          <h2>Admin Access Required</h2>
          <p>This page requires admin privileges. Your current roles are: {userRoles.join(', ')}</p>
          <p>Please contact the system administrator if you believe you should have access.</p>
          <button 
            onClick={() => window.location.href = '/'}
            style={{
              padding: '0.8rem 1.5rem',
              backgroundColor: '#4a90e2',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '1rem',
              fontSize: '1rem'
            }}
          >
            Return to Home
          </button>
        </div>
      );
    }
    
    // Default handling for artist access
    return (
      <div style={{ 
        minHeight: '100vh', 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center',
        backgroundColor: '#030617',
        color: 'white',
        padding: '2rem',
        textAlign: 'center'
      }}>
        <h2>Access Denied</h2>
        <p>This portal is for artists only. To become an artist, please complete your artist profile.</p>
        <button 
          onClick={() => window.location.href = '/become-artist'}
          style={{
            padding: '0.8rem 1.5rem',
            backgroundColor: '#4a90e2',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginTop: '1rem',
            fontSize: '1rem'
          }}
        >
          Become an Artist
        </button>
      </div>
    );
  }

  return children;
};

export default ProtectedRoute;
