import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import { db, collections, ROLES } from '../../firebase.marketplace';
import { collection, query, where, getDocs } from 'firebase/firestore';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #030617 0%, #0a1128 100%);
  padding: 1rem;
`;

const FormContainer = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  backdrop-filter: blur(10px);
`;

const Logo = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #4a90e2;
  font-weight: bold;
`;

const Title = styled.h2`
  color: white;
  text-align: center;
  margin-bottom: 2rem;
`;

const Subtitle = styled.p`
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  margin-bottom: 2rem;
  font-size: 0.9rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.8rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.05);
  color: white;
  width: 100%;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    outline: none;
    border-color: #4a90e2;
  }
`;

const Button = styled.button`
  padding: 0.8rem;
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;

  &:hover {
    background: #357abd;
  }

  &:disabled {
    background: #666;
    cursor: not-allowed;
  }
`;

const GoogleButton = styled(Button)`
  background: #db4437;

  &:hover {
    background: #c1351d;
  }
`;

const ErrorMessage = styled.div`
  color: #ff6b6b;
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
`;

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 1rem;
  font-size: 0.9rem;

  a {
    color: #4a90e2;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { login, signInWithGoogle, currentUser, hasRole, loading: authLoading } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    // Clear error when user starts typing
    if (error) setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const user = await login(formData.email, formData.password);
      // Log the user's UID for admin setup
      console.log('Your UID for admin setup:', user.uid);
      
      // Navigate directly to marketplace for now, skipping role checks
      navigate('/marketplace');
      
      /* Commenting out role checks for now as they're causing permission errors
      // After successful login, check if we have user data
      if (!hasRole(ROLES.ARTIST)) {
        // Check if there's a pending artist request
        const requestsRef = collection(db, collections.ARTIST_REQUESTS);
        const q = query(
          requestsRef,
          where('userId', '==', user.uid),
          where('status', '==', 'pending')
        );
        const requests = await getDocs(q);

        if (!requests.empty) {
          throw new Error('Your artist application is still pending approval.');
        } else {
          throw new Error('Access denied. This portal is for artists only. Please register as an artist first.');
        }
      }
      navigate('/marketplace');
      */
    } catch (err) {
      console.error('Login error in SignIn:', err);
      
      // Handle permission errors more gracefully
      if (err.code === 'permission-denied') {
        setError('Authentication successful, but there was an issue accessing your account data. Please try again later.');
      } else {
        setError(err.message || 'Failed to sign in');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    setError(null);

    try {
      const user = await signInWithGoogle();
      console.log('Your UID for admin setup:', user.uid);
      navigate('/marketplace');
    } catch (err) {
      console.error('Google Sign In Error:', err);
      setError(err.message || 'Failed to sign in with Google');
    } finally {
      setLoading(false);
    }
  };

  if (authLoading) {
    return (
      <Container>
        <FormContainer>
          <Logo>KONZO</Logo>
          <Title>Loading...</Title>
        </FormContainer>
      </Container>
    );
  }

  return (
    <Container>
      <FormContainer>
        <Logo>KONZO</Logo>
        <Title>Artist Sign In</Title>
        <Subtitle>Access your artist dashboard to manage your music and sales</Subtitle>
        <Form onSubmit={handleSubmit}>
          <Input
            type="email"
            name="email"
            placeholder="Artist Email"
            value={formData.email}
            onChange={handleChange}
            required
            disabled={loading}
          />
          <Input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
            disabled={loading}
          />
          <Button type="submit" disabled={loading}>
            {loading ? 'Signing in...' : 'Sign In'}
          </Button>
          <GoogleButton type="button" onClick={handleGoogleSignIn} disabled={loading}>
            Sign In with Google
          </GoogleButton>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Form>
        <Links>
          <a href="/marketplace/auth/signup">Need an account? Sign Up</a>
          <a href="/marketplace/artist-registration">Become an Artist</a>
        </Links>
      </FormContainer>
    </Container>
  );
};

export default SignIn;
