import { useState, useEffect } from 'react';
import { db } from '../firebase';
import { 
    collection, 
    query, 
    onSnapshot, 
    where, 
    orderBy,
    getDocs 
} from 'firebase/firestore';

const useFirestore = (collectionName, options = {}) => {
    const [docs, setDocs] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let unsubscribe;
        let isSubscribed = true;

        const fetchData = async () => {
            if (!collectionName) {
                const error = new Error('Collection name is required');
                console.error(error.message);
                setError(error);
                setLoading(false);
                return;
            }

            try {
                // Check if Firebase is initialized
                if (!db) {
                    throw new Error('Firebase database is not initialized');
                }

                console.log(`Setting up real-time listener for collection: ${collectionName}`);
                const collectionRef = collection(db, collectionName);
                
                // First check if we can get any documents without ordering
                const initialSnapshot = await getDocs(collection(db, collectionName));
                if (initialSnapshot.empty) {
                    console.log(`Collection ${collectionName} is empty`);
                    setDocs([]);
                    setLoading(false);
                    return;
                }

                // Build the query
                let queryRef = collectionRef;

                if (options.where) {
                    console.log(`Applying where filter: ${options.where.join(', ')}`);
                    queryRef = query(queryRef, where(options.where[0], options.where[1], options.where[2]));
                }

                if (options.orderBy) {
                    // Check if the orderBy field exists in at least one document
                    const [field, direction] = options.orderBy;
                    const hasField = initialSnapshot.docs.some(doc => doc.data()[field] !== undefined);
                    
                    if (hasField) {
                        console.log(`Applying orderBy: ${field}, ${direction}`);
                        queryRef = query(queryRef, orderBy(field, direction));
                    } else {
                        console.log(`Warning: orderBy field '${field}' not found in documents, skipping orderBy`);
                    }
                }

                unsubscribe = onSnapshot(queryRef, (snapshot) => {
                    if (!isSubscribed) {
                        console.log('Component unmounted, cancelling data update');
                        return;
                    }

                    const documents = [];
                    snapshot.forEach(doc => {
                        documents.push({ ...doc.data(), id: doc.id });
                    });

                    console.log(`Successfully fetched ${documents.length} items from ${collectionName}`);
                    if (documents.length === 0) {
                        console.log(`Warning: No items found in collection ${collectionName}`);
                    }

                    setDocs(documents);
                    setError(null); // Clear any previous errors
                    setLoading(false);
                }, (err) => {
                    console.error(`Error in ${collectionName} snapshot listener:`, err);
                    if (!isSubscribed) return;

                    let errorMessage = 'An error occurred while fetching data';
                    
                    if (err.code === 'permission-denied') {
                        errorMessage = `Access denied to collection ${collectionName}. Please check Firestore rules.`;
                    } else if (err.code === 'not-found') {
                        errorMessage = `Collection ${collectionName} not found.`;
                    } else if (err.code === 'unavailable') {
                        errorMessage = 'Firebase service is currently unavailable. Please try again later.';
                    }

                    setError(new Error(errorMessage));
                    setLoading(false);
                });

            } catch (err) {
                console.error('Firebase setup error:', err);
                if (isSubscribed) {
                    setError(new Error('Failed to initialize Firebase connection. Please check your configuration.'));
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isSubscribed = false;
            if (unsubscribe) {
                console.log(`Unsubscribing from ${collectionName} listener`);
                unsubscribe();
            }
        };
    }, [collectionName, JSON.stringify(options)]);

    return { data: docs, error, loading };
};

export default useFirestore;
