import React, { useState } from 'react';
import styled from 'styled-components';
import { Link as LinkR, useLocation } from 'react-router-dom';
import { FaHome, FaStore, FaUserCircle, FaBoxOpen, FaChartLine } from 'react-icons/fa';
import { FiMusic } from 'react-icons/fi';
import { MdOutlineRadio, MdLiveTv } from "react-icons/md";
import { PiTelevisionSimpleThin } from "react-icons/pi";
import { BsCalendarEvent, BsCalendar } from "react-icons/bs";
import logo from '../../../images/konzomobi.png';

const Navbar = () => {
  const location = useLocation();
  const [hoveredItem, setHoveredItem] = useState(null);

  const Nav3 = [
    {
      id: 1,
      name: "Marketplace",
      icon: <FaStore />,
      link: "/marketplace",
      subItems: [
        {
          id: "artist-dashboard",
          name: "Artist Dashboard",
          icon: <FaUserCircle />,
          link: "/artist-dashboard"
        },
        {
          id: "my-products",
          name: "My Products",
          icon: <FaBoxOpen />,
          link: "/marketplace/products"
        },
        {
          id: "sales",
          name: "Sales History",
          icon: <FaChartLine />,
          link: "/marketplace/sales"
        }
      ]
    },
    {
      id: 2,
      name: "Live Events",
      icon: <BsCalendar />,
      link: "/streaming-events",
      subItems: [
        {
          id: "upcoming-events",
          name: "Upcoming Events",
          icon: <BsCalendarEvent />,
          link: "/streaming-events"
        },
        {
          id: "my-tickets",
          name: "My Tickets",
          icon: <FaUserCircle />,
          link: "/streaming-events/my-tickets"
        },
        {
          id: "past-events",
          name: "Past Events",
          icon: <BsCalendar />,
          link: "/streaming-events/past"
        }
      ]
    }
  ];

  return (
    <Nav>
      <NavbarContainer>
        <Logo to="/">
          <LogoImage src={logo} alt="Logo" />
        </Logo>
        <NavMenu>
          <NavItem
            onMouseEnter={() => setHoveredItem('home')}
            onMouseLeave={() => setHoveredItem(null)}
            isActive={location.pathname === '/'}
          >
            <NavLinks to="/" isHovered={hoveredItem === 'home'}>
              <IconWrapper>
                <FaHome />
              </IconWrapper>
              <LinkText>Home</LinkText>
            </NavLinks>
          </NavItem>
          <NavItem
            onMouseEnter={() => setHoveredItem('music')}
            onMouseLeave={() => setHoveredItem(null)}
            isActive={location.pathname === '/konzohits'}
          >
            <NavLinks to="/konzohits" isHovered={hoveredItem === 'music'}>
              <IconWrapper>
                <FiMusic />
              </IconWrapper>
              <LinkText>Konzo Music</LinkText>
            </NavLinks>
          </NavItem>
          <NavItem
            onMouseEnter={() => setHoveredItem('radio')}
            onMouseLeave={() => setHoveredItem(null)}
            isActive={location.pathname === '/radios'}
          >
            <NavLinks to="/radios" isHovered={hoveredItem === 'radio'}>
              <IconWrapper>
                <MdOutlineRadio />
              </IconWrapper>
              <LinkText>Radio</LinkText>
            </NavLinks>
          </NavItem>
          <NavLinks to="/channels" isHovered={hoveredItem === 'tv'}>
              <IconWrapper>
                <PiTelevisionSimpleThin />
              </IconWrapper>
              <LinkText>TV Stations</LinkText>
            </NavLinks>
          <NavItem
            onMouseEnter={() => setHoveredItem('marketplace')}
            onMouseLeave={() => setHoveredItem(null)}
            isActive={location.pathname.startsWith('/marketplace')}
          >
            <NavLinks to="/marketplace" isHovered={hoveredItem === 'marketplace'}>
              <IconWrapper>
                <FaStore />
              </IconWrapper>
              <LinkText>Marketplace</LinkText>
            </NavLinks>
          </NavItem>
          <NavItem
            onMouseEnter={() => setHoveredItem('events')}
            onMouseLeave={() => setHoveredItem(null)}
            isActive={location.pathname === '/streaming-events'}
          >
            <NavLinks to="/streaming-events" isHovered={hoveredItem === 'events'}>
              <IconWrapper>
                <BsCalendar />
              </IconWrapper>
              <LinkText>Live Events</LinkText>
            </NavLinks>
          </NavItem>
          <NavItem
            onMouseEnter={() => setHoveredItem('tv')}
            onMouseLeave={() => setHoveredItem(null)}
            isActive={location.pathname === '/channels'}
          >
       
          </NavItem>
        </NavMenu>
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;

const Nav = styled.nav`
  background: linear-gradient(to right, #161c2f, #1f2642);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1200px;

  @media screen and (max-width: 768px) {
    padding: 0 12px;
  }
`;

const Logo = styled(LinkR)`
  display: flex;
  align-items: center;
  margin-right: 24px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media screen and (max-width: 768px) {
    margin-right: 12px;
  }
`;

const LogoImage = styled.img`
  height: 45px;
  width: auto;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));

  @media screen and (max-width: 768px) {
    height: 35px;
  }
`;

const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 8px;

  @media screen and (max-width: 768px) {
    gap: 4px;
  }
`;

const NavItem = styled.li`
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: ${props => props.isActive ? '100%' : '0'};
    height: 3px;
    background: linear-gradient(to right, #7efd2d, #32cd32);
    transition: all 0.3s ease;
    transform: translateX(-50%);
    opacity: ${props => props.isActive ? '1' : '0'};
  }

  &:hover::after {
    width: 100%;
    opacity: 1;
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  margin-right: 8px;
  transition: transform 0.3s ease;
`;

const LinkText = styled.span`
  font-weight: 500;
  letter-spacing: 0.5px;
`;

const NavLinks = styled(LinkR)`
  color: ${props => props.isHovered ? '#7efd2d' : '#fff'};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 16px;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  font-size: 0.95rem;

  &:hover {
    color: #7efd2d;
    background: rgba(126, 253, 45, 0.1);
    
    ${IconWrapper} {
      transform: translateY(-2px);
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 12px;
    font-size: 0.85rem;

    ${LinkText} {
      display: none;
    }

    ${IconWrapper} {
      margin-right: 0;
      font-size: 1.4rem;
    }
  }
`;
