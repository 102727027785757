import {React, useEffect} from 'react'
import { db } from '../../firebase'
import { collection, onSnapshot } from 'firebase/firestore'
import {useDispatch} from "react-redux"
import {setRecents} from "../../features/recent/recentSlice"
import RecentlyAdded from '../audio/Songs/RecentlyAdded'
import GoogleAd2 from '../../components/GoogleAd2';
import Nav3 from '../Navs/SideView/Nav3'
import ViewCounter from '../ViewCounter'
import Helmet from 'react-helmet'

function RecentAddedHitsPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    const collectionRef = collection(db, "recents");
    
    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        const tempRecents = snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
        });
        console.log(tempRecents);
        dispatch(setRecents(tempRecents));
    }, (error) => {
        console.error('Error fetching recents:', error);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [dispatch])


  return (
    <div style={{background:'#030617'}}>
    <Helmet>
          <title>Recent Added Hits</title>
        <meta
  name="description"
  content="Best of Kasese, fully equipped entertainment online platform  "
/>
<meta name="keywords" content="mp3 song of, mp3 song in, mp3 song, Mp3 song, download mp3 song, mp3 song download, mp3 song download free"/>

<link rel="apple-touch-icon" href="/logo512.jpg" />
<style type="text/css"></style>

    </Helmet>
    <Nav3 />
    <RecentlyAdded />
    <GoogleAd2 />
    <ViewCounter path="/musics" />
    </div>
  )
}

export default RecentAddedHitsPage;
