import { initializeApp } from 'firebase/app';
import { 
    getFirestore, 
    enableIndexedDbPersistence, 
    CACHE_SIZE_UNLIMITED,
    collection,
    doc,
    getDoc,
    setDoc,
    query,
    where,
    getDocs,
    updateDoc,
    arrayUnion,
    serverTimestamp
} from 'firebase/firestore';
import { 
    getAuth, 
    GoogleAuthProvider,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    sendPasswordResetEmail
} from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// Main app config for non-marketplace features
const config = {
    apiKey: "AIzaSyB5QW9Wack76vdLVeBKlVfLacQismjxCBg",
    authDomain: "konzo-vibes.firebaseapp.com",
    projectId: "konzo-vibes",
    storageBucket: "konzo-vibes.appspot.com",
    messagingSenderId: "542289244063",
    appId: "1:542289244063:web:4c8983b92b48f87e3417aa",
    measurementId: "G-6CFPP72K5C"
};

// Initialize Firebase app
const mainApp = initializeApp(config, 'main');

// Initialize Firebase services (excluding auth)
const db = getFirestore(mainApp);
const storage = getStorage(mainApp);

// Enable offline persistence
try {
    enableIndexedDbPersistence(db, {
        cacheSizeBytes: CACHE_SIZE_UNLIMITED
    }).catch((err) => {
        if (err.code === 'failed-precondition') {
            console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
        } else if (err.code === 'unimplemented') {
            console.warn('The current browser does not support persistence.');
        }
    });
} catch (err) {
    console.warn('Error enabling persistence:', err);
}

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAHVoDm6rqEvUc8C8oqki4kpjLd2MxhYw8",
    authDomain: "konzo-marketplace.firebaseapp.com",
    projectId: "konzo-marketplace",
    storageBucket: "konzo-marketplace.firebasestorage.app",
    messagingSenderId: "177859503793",
    appId: "1:177859503793:web:e5d72f16cc47bf0bce6490",
    measurementId: "G-LGRMWP1HBN"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const marketplaceDb = getFirestore(app);
const marketplaceStorage = getStorage(app);

// Initialize Google Auth Provider
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
    prompt: 'select_account'
});

// Collections references
export const collections = {
    USERS: 'users',
    ARTIST_REQUESTS: 'artist_requests',
    ROLES: 'roles'
};

// Role definitions
export const ROLES = {
    USER: 'user',
    ARTIST: 'artist',
    ADMIN: 'admin'
};

// Enable offline persistence with error handling
try {
    enableIndexedDbPersistence(marketplaceDb, {
        cacheSizeBytes: CACHE_SIZE_UNLIMITED
    }).catch((err) => {
        if (err.code === 'failed-precondition') {
            console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
        } else if (err.code === 'unimplemented') {
            console.warn('The current browser does not support persistence.');
        }
    });
} catch (err) {
    console.warn('Error enabling persistence:', err);
}

// Admin setup helper function
export const ensureAdminUser = async (uid) => {
    try {
        const userRef = doc(marketplaceDb, collections.USERS, uid);
        const userDoc = await getDoc(userRef);

        if (!userDoc.exists()) {
            // Create new user document with admin role
            await setDoc(userRef, {
                roles: [ROLES.ADMIN, ROLES.USER],
                isAdmin: true,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            });
            console.log('Admin user created successfully');
        } else {
            // Update existing user to have admin role if they don't already
            const userData = userDoc.data();
            if (!userData.roles?.includes(ROLES.ADMIN)) {
                const updatedRoles = [...(userData.roles || []), ROLES.ADMIN];
                await setDoc(userRef, {
                    ...userData,
                    roles: updatedRoles,
                    isAdmin: true,
                    updatedAt: new Date().toISOString()
                }, { merge: true });
                console.log('User updated with admin role');
            } else {
                console.log('User already has admin role');
            }
        }
    } catch (error) {
        console.error('Error in ensureAdminUser:', error);
        throw error;
    }
};

export {
    mainApp,
    db,
    storage,
    app,
    auth,
    marketplaceDb,
    marketplaceStorage,
    googleProvider,
    // Firestore functions
    collection,
    doc,
    getDoc,
    setDoc,
    query,
    where,
    getDocs,
    updateDoc,
    arrayUnion,
    serverTimestamp,
    // Auth functions
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithPopup
};