import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { motion } from 'framer-motion';
import { FaTicketAlt, FaCalendar, FaClock, FaLink } from 'react-icons/fa';
import LoadingSpinner from '../common/LoadingSpinner';

const Container = styled.div`
  padding: 2rem;
`;

const Header = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  color: white;
  margin-bottom: 1rem;
  font-size: 2rem;
  text-align: center;

  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background: #4a90e2;
    margin: 1rem auto;
    border-radius: 2px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const TicketCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid rgba(74, 144, 226, 0.1);
`;

const EventImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const TicketContent = styled.div`
  padding: 1.5rem;
`;

const EventTitle = styled.h3`
  color: white;
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4a90e2;
  font-size: 0.9rem;

  svg {
    font-size: 1.1rem;
  }
`;

const AccessButton = styled.button`
  width: 100%;
  padding: 0.8rem;
  background: ${props => props.active ? '#4a90e2' : '#666'};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: ${props => props.active ? 'pointer' : 'not-allowed'};
  transition: background 0.3s ease;

  &:hover {
    background: ${props => props.active ? '#357abd' : '#666'};
  }
`;

const NoTicketsMessage = styled.div`
  text-align: center;
  padding: 4rem 2rem;
  color: #888;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
`;

const NoTicketsIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #4a90e2;
`;

const MyTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const ticketsQuery = query(
          collection(db, 'tickets'),
          where('userId', '==', 'current-user-id') // Replace with actual user ID
        );
        const ticketsSnapshot = await getDocs(ticketsQuery);
        
        // Get event details for each ticket
        const ticketsWithEvents = await Promise.all(
          ticketsSnapshot.docs.map(async (doc) => {
            const ticket = {
              id: doc.id,
              ...doc.data()
            };
            
            // Get event details
            const eventDoc = await getDoc(doc(db, 'streamEvents', ticket.eventId));
            return {
              ...ticket,
              event: {
                id: eventDoc.id,
                ...eventDoc.data()
              }
            };
          })
        );

        setTickets(ticketsWithEvents);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, []);

  const isEventActive = (eventDate, eventTime) => {
    const eventDateTime = new Date(eventDate + 'T' + eventTime);
    const now = new Date();
    return eventDateTime > now;
  };

  if (loading) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }

  if (tickets.length === 0) {
    return (
      <Container>
        <Header>
          <Title>My Tickets</Title>
        </Header>
        <NoTicketsMessage>
          <NoTicketsIcon>
            <FaTicketAlt />
          </NoTicketsIcon>
          <p>You haven't purchased any tickets yet.</p>
        </NoTicketsMessage>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Title>My Tickets</Title>
      </Header>
      <Grid>
        {tickets.map(ticket => {
          const isActive = isEventActive(ticket.event.date, ticket.event.time);
          return (
            <TicketCard
              key={ticket.id}
              whileHover={{ y: -5 }}
              transition={{ duration: 0.2 }}
            >
              <EventImage src={ticket.event.imageUrl} alt={ticket.event.title} />
              <TicketContent>
                <EventTitle>{ticket.event.title}</EventTitle>
                <InfoGrid>
                  <InfoItem>
                    <FaCalendar />
                    <span>{new Date(ticket.event.date).toLocaleDateString()}</span>
                  </InfoItem>
                  <InfoItem>
                    <FaClock />
                    <span>{ticket.event.time}</span>
                  </InfoItem>
                  <InfoItem>
                    <FaTicketAlt />
                    <span>Ticket #{ticket.id.slice(-6)}</span>
                  </InfoItem>
                  <InfoItem>
                    <FaLink />
                    <span>{isActive ? 'Active' : 'Expired'}</span>
                  </InfoItem>
                </InfoGrid>
                <AccessButton
                  active={isActive}
                  onClick={() => {
                    if (isActive && ticket.event.streamUrl) {
                      window.open(ticket.event.streamUrl, '_blank');
                    }
                  }}
                >
                  {isActive ? 'Join Stream' : 'Event Ended'}
                </AccessButton>
              </TicketContent>
            </TicketCard>
          );
        })}
      </Grid>
    </Container>
  );
};

export default MyTickets;
