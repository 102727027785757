import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { doc, getDoc, collection, query, where, getDocs, limit } from 'firebase/firestore';
import { db } from '../../firebase';
import LoadingSpinner from '../common/LoadingSpinner';
import { motion } from 'framer-motion';
import { FaShoppingCart, FaStar, FaUser, FaCalendar } from 'react-icons/fa';

const Container = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  overflow: hidden;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ImageSection = styled.div`
  position: relative;
  padding: 2rem;
`;

const MainImage = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 12px;
`;

const ThumbnailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-top: 1rem;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  opacity: ${props => props.active ? 1 : 0.6};
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const InfoSection = styled.div`
  padding: 2rem;
`;

const Title = styled.h1`
  color: white;
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Price = styled.div`
  color: #4a90e2;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
`;

const Description = styled.p`
  color: #888;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const Stats = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
`;

const StatItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  font-size: 0.9rem;

  svg {
    color: #4a90e2;
  }
`;

const AddToCartButton = styled(motion.button)`
  width: 100%;
  padding: 1rem;
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1rem;

  &:hover {
    background: #357abd;
  }
`;

const BuyNowButton = styled(AddToCartButton)`
  background: transparent;
  border: 2px solid #4a90e2;

  &:hover {
    background: rgba(74, 144, 226, 0.1);
  }
`;

const ArtistInfo = styled.div`
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const ArtistHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const ArtistAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
`;

const ArtistName = styled.h3`
  color: white;
  font-size: 1.2rem;
`;

const ArtistBio = styled.p`
  color: #888;
  font-size: 0.9rem;
  line-height: 1.6;
`;

const RelatedProducts = styled.div`
  margin-top: 3rem;
`;

const RelatedTitle = styled.h2`
  color: white;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
`;

const RelatedGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
`;

const RelatedCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
`;

const RelatedImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const RelatedInfo = styled.div`
  padding: 1rem;
`;

const RelatedProductName = styled.h4`
  color: white;
  margin-bottom: 0.5rem;
  font-size: 1rem;
`;

const RelatedPrice = styled.div`
  color: #4a90e2;
  font-weight: 600;
`;

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(0);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productDoc = await getDoc(doc(db, 'products', id));
        if (productDoc.exists()) {
          setProduct({
            id: productDoc.id,
            ...productDoc.data()
          });

          // Fetch related products
          const relatedQuery = query(
            collection(db, 'products'),
            where('category', '==', productDoc.data().category),
            where('id', '!=', id),
            limit(4)
          );
          const relatedSnapshot = await getDocs(relatedQuery);
          setRelatedProducts(
            relatedSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }))
          );
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }

  if (!product) {
    return (
      <Container>
        <div>Product not found</div>
      </Container>
    );
  }

  return (
    <Container>
      <ProductGrid>
        <ImageSection>
          <MainImage src={product.images[selectedImage]} alt={product.title} />
          <ThumbnailGrid>
            {product.images.map((image, index) => (
              <Thumbnail
                key={index}
                src={image}
                alt={`${product.title} thumbnail ${index + 1}`}
                active={selectedImage === index}
                onClick={() => setSelectedImage(index)}
              />
            ))}
          </ThumbnailGrid>
        </ImageSection>

        <InfoSection>
          <Title>{product.title}</Title>
          <Price>${product.price.toFixed(2)}</Price>
          <Description>{product.description}</Description>

          <Stats>
            <StatItem>
              <FaStar />
              <span>{product.rating} ({product.reviews} reviews)</span>
            </StatItem>
            <StatItem>
              <FaUser />
              <span>{product.sales} sold</span>
            </StatItem>
            <StatItem>
              <FaCalendar />
              <span>Listed {new Date(product.createdAt.toDate()).toLocaleDateString()}</span>
            </StatItem>
          </Stats>

          <AddToCartButton
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <FaShoppingCart />
            Add to Cart
          </AddToCartButton>

          <BuyNowButton
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            Buy Now
          </BuyNowButton>

          <ArtistInfo>
            <ArtistHeader>
              <ArtistAvatar src={product.artist.avatarUrl} alt={product.artist.name} />
              <ArtistName>{product.artist.name}</ArtistName>
            </ArtistHeader>
            <ArtistBio>{product.artist.bio}</ArtistBio>
          </ArtistInfo>
        </InfoSection>
      </ProductGrid>

      <RelatedProducts>
        <RelatedTitle>Related Products</RelatedTitle>
        <RelatedGrid>
          {relatedProducts.map(relatedProduct => (
            <RelatedCard
              key={relatedProduct.id}
              onClick={() => navigate(`/marketplace/products/${relatedProduct.id}`)}
              whileHover={{ y: -5 }}
              transition={{ duration: 0.2 }}
            >
              <RelatedImage src={relatedProduct.images[0]} alt={relatedProduct.title} />
              <RelatedInfo>
                <RelatedProductName>{relatedProduct.title}</RelatedProductName>
                <RelatedPrice>${relatedProduct.price.toFixed(2)}</RelatedPrice>
              </RelatedInfo>
            </RelatedCard>
          ))}
        </RelatedGrid>
      </RelatedProducts>
    </Container>
  );
};

export default ProductDetail;
