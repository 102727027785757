import React from 'react';
import styled from 'styled-components';
import AuthButtons from './AuthButtons';

const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(180deg, #030617 0%, #0a1128 100%);
`;

const Header = styled.header`
  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
`;

const Title = styled.h1`
  color: white;
  margin: 0;
  font-size: 1.5rem;
`;

const Content = styled.main`
  padding: 2rem;
`;

const MarketplaceLayout = ({ children }) => {
  return (
    <Container>
      <Header>
        <Title>KONZO Marketplace</Title>
        <AuthButtons />
      </Header>
      <Content>
        {children}
      </Content>
    </Container>
  );
};

export default MarketplaceLayout;
