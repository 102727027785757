import React from 'react';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  padding: 20px;
`;

const ErrorBox = styled.div`
  background-color: #fff3f3;
  border: 1px solid #dc3545;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  max-width: 500px;
  width: 100%;
`;

const ErrorTitle = styled.h3`
  color: #dc3545;
  margin: 0 0 10px 0;
`;

const ErrorText = styled.p`
  color: #666;
  margin: 0;
`;

const ErrorMessage = ({ message = 'An error occurred. Please try again later.' }) => (
  <ErrorContainer>
    <ErrorBox>
      <ErrorTitle>Error</ErrorTitle>
      <ErrorText>{message}</ErrorText>
    </ErrorBox>
  </ErrorContainer>
);

export default ErrorMessage;
