import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import TextAnimation from './TextAnimation';
import Button from '@mui/material/Button';
import { styled as muiStyled } from '@mui/material/styles';

const StyledButton = muiStyled(Button)(({ theme }) => ({
  marginTop: '20px',
  padding: '10px 24px',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
}));

const Content = () => {
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: 'easeOut', staggerChildren: 0.2 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div initial="hidden" animate="visible" variants={containerVariants}>
      <HeroContainer>
        <LoopVideo />
        <HeroContent>
          <Title variants={itemVariants}>
            <h1>We're revamping the station...</h1>
            <h1>New name, same great tunes!</h1>
          </Title>
          <Desc variants={itemVariants}>
            The snow is melting, but our passion is heating up! <br />
            Snow FM is rebranding and coming back stronger than ever.
          </Desc>
          <motion.div variants={itemVariants}>
            <TextAnimation />
          </motion.div>
          <motion.div variants={itemVariants}>
            <a href="https://www.kihembo.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <StyledButton variant="contained" color="primary">
                Discover More
              </StyledButton>
            </a>
          </motion.div>
        </HeroContent>
      </HeroContainer>
    </motion.div>
  );
};

export default Content;

const HeroContainer = styled(motion.div)`
  background: linear-gradient(180deg, #030617 0%, #0a1128 100%);
  height: auto;
  min-height: 360px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  padding: 20px;
`;

const LoopVideo = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
  background: url('/path-to-video') center/cover no-repeat;
`;

const HeroContent = styled(motion.div)`
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
  padding: 20px;
`;

const Title = styled(motion.div)`
  h1 {
    color: #add8e6;
    font-size: 1.75rem;
    font-weight: 700;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    margin: 0;
    letter-spacing: 1px;

    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
`;

const Desc = styled(motion.p)`
  color: #fff;
  font-size: 1.1rem;
  max-width: 800px;
  line-height: 1.5;

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
`;
