import React, { useEffect } from 'react';
import { db } from './firebase.js';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { useLocation, Routes, Route, Navigate } from "react-router-dom";
import Home from './components/pages/Home.js';
import GlobalStyle from './globalStyles.js';
import Post from './components/pages/Post.js';
import Privacy from './components/pages/Privacy.js';
import About from './components/pages/About.js';
import Upload from './components/pages/Upload.js';
import Advertise from './components/pages/Advertise.js';
import Nonstops from './components/audio/Songs/Mp3Converter/Converter2.js';
import Footer2 from './components/Footer2.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga';
import Converter from './components/audio/Songs/Mp3Converter/Converter.js';
import TalentConverter from './components/audio/Songs/Mp3Converter/TalentConverter.js';
import NonstopPage from './components/pages/NonstopPage.js';
import NewTalentPage from './components/pages/NewTalentPage.js';
import RecentlyAddedHitsConverter from './components/audio/Songs/Mp3Converter/RecentAddedHitsConverter.js';
import RecentAddedHitsPage from './components/pages/RecentlyAddedHitsPage.js';
import ScrollToTop from './components/ScrollToTop.js';
import MarketplaceRoutes from './components/marketplace/MarketplaceRoutes.js';
import { AuthProvider } from './contexts/AuthContext.js';
import ProtectedRoute from './components/auth/ProtectedRoute.js';
import ArtistDashboard from './components/auth/ArtistDashboard.js';
import AdminDashboard from './components/auth/AdminDashboard.js';
import Song from './components/pages/Song.js';
import StreamingEvents from './components/streaming/StreamingEvents.js';
import EventDetails from './components/streaming/EventDetails.js';
import MarketplaceLayout from './components/marketplace/MarketplaceLayout.js';
import ArtistDashboardMarketplace from './components/marketplace/ArtistDashboard.js';
import CreateEventForm from './components/streaming/CreateEventForm.js';
import ProductList from './components/marketplace/ProductList.js';
import SalesHistory from './components/marketplace/SalesHistory.js';
import MyTickets from './components/streaming/MyTickets.js';
import { CartProvider } from './context/CartContext.js';
import Cart from './components/marketplace/Cart.js';
import ProductDetail from './components/marketplace/ProductDetail.js';
import Checkout from './components/marketplace/Checkout.js';
import OrderSuccess from './components/marketplace/OrderSuccess.js';
import OrderHistory from './components/marketplace/OrderHistory.js';
import OrderDetails from './components/marketplace/OrderDetails.js';
import BecomeArtist from './components/auth/BecomeArtist.js';
import AdminPanel from './components/marketplace/AdminPanel.js';
import { ROLES } from './firebase.marketplace.js';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ExternalRedirect = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);
  return null;
};

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-C8BTBL2H7M');
    const trackPage = (page) => {
      ReactGA.set({ page });
      ReactGA.pageview(page);
    };
    
    trackPage(location.pathname + location.search);
  }, [location]);

  return (
    <AuthProvider>
      <ScrollToTop />
      <GlobalStyle />
      <CartProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/radio/:id" element={<TalentConverter />} />
          <Route path="/tv/:id" element={<Nonstops />} />
          <Route path="/post/:slug" element={<Post />} />
          <Route path="/channels" element={<NonstopPage />} />
          <Route path="/radios" element={<NewTalentPage />} />
          <Route path="/music/:id" element={<Converter />} />
          <Route path="/musics/:id" element={<RecentlyAddedHitsConverter />} />
          <Route path="/musics" element={<RecentAddedHitsPage />} />
          <Route path="/konzohits" element={<Song />} />
          <Route path="/marketplace/*" element={<MarketplaceRoutes />} />
          <Route path="/streaming-events" element={<StreamingEvents />} />
          <Route path="/streaming-events/:id" element={<EventDetails />} />
          <Route path="/become-artist" element={<BecomeArtist />} />
          <Route path="/artist-dashboard" element={
            <ProtectedRoute requiredRole="artist">
              <ArtistDashboard />
            </ProtectedRoute>
          } />
          <Route path="/admin-dashboard" element={
            <ProtectedRoute requiredRole={ROLES.ADMIN}>
              <AdminDashboard />
            </ProtectedRoute>
          } />
          <Route path="/admin-panel" element={
            <ProtectedRoute requiredRole={ROLES.ADMIN}>
              <AdminPanel />
            </ProtectedRoute>
          } />
          <Route path="/make-admin" element={
            <div style={{ 
              minHeight: '100vh', 
              display: 'flex', 
              flexDirection: 'column',
              alignItems: 'center', 
              justifyContent: 'center',
              backgroundColor: '#030617',
              color: 'white',
              padding: '2rem',
              textAlign: 'center'
            }}>
              <h2>Make Wilbert Admin</h2>
              <p>Click the button below to make wilbert@kihembo.com an admin:</p>
              <button 
                onClick={() => {
                  (async function() {
                    try {
                      const adminEmail = 'wilbert@kihembo.com';
                      
                      alert(`Starting admin setup for ${adminEmail}...`);
                      
                      // Find user by email
                      const usersRef = collection(db, 'users');
                      const q = query(usersRef, where('email', '==', adminEmail));
                      const querySnapshot = await getDocs(q);
                      
                      if (querySnapshot.empty) {
                        alert(`⚠️ No user found with email: ${adminEmail}`);
                        return;
                      }
                      
                      // Should only be one user with this email
                      const userDoc = querySnapshot.docs[0];
                      const userId = userDoc.id;
                      
                      // Get current user data
                      const userData = userDoc.data();
                      
                      // Prepare roles object
                      let roles = userData.roles || {};
                      
                      // If roles is an array, convert to object
                      if (Array.isArray(roles)) {
                        const rolesObj = {};
                        roles.forEach(role => {
                          rolesObj[role] = true;
                        });
                        roles = rolesObj;
                      }
                      
                      // Ensure all required roles are set
                      roles.admin = true;
                      roles.artist = true;
                      roles.user = true;
                      
                      // Update the user document with admin privileges
                      const userDocRef = doc(db, 'users', userId);
                      await updateDoc(userDocRef, {
                        roles: roles,
                        isAdmin: true,
                        updatedAt: new Date().toISOString()
                      });
                      
                      alert(`✅ User ${adminEmail} (${userId}) updated with admin privileges. Please refresh the page.`);
                      
                    } catch (error) {
                      alert(`❌ Error setting up admin: ${error.message}`);
                      console.error('Error setting up admin:', error);
                    }
                  })();
                }}
                style={{
                  padding: '0.8rem 1.5rem',
                  backgroundColor: '#4a90e2',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  marginTop: '1rem',
                  fontSize: '1rem'
                }}
              >
                Make Wilbert Admin
              </button>
            </div>
          } />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/about" element={<About />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/advertise" element={<Advertise />} />
          <Route path="/my-tickets" element={<MyTickets />} />
          <Route path="/create-event" element={<CreateEventForm />} />
          {/* Catch all route for unmatched paths */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer2 />
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
