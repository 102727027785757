import React, { createContext, useContext, useState, useEffect } from 'react';
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    GoogleAuthProvider,
    signInWithPopup,
    sendPasswordResetEmail
} from 'firebase/auth';
import {
    doc,
    setDoc,
    getDoc,
    collection,
    addDoc,
    serverTimestamp,
    query,
    where,
    getDocs
} from 'firebase/firestore';
import { auth, db, collections, ROLES, googleProvider } from '../firebase.marketplace';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    // Helper function to get user roles
    const getUserRoles = async (uid) => {
        try {
            console.log('Getting roles for user:', uid);
            const userDoc = await getDoc(doc(db, collections.USERS, uid));
            
            if (userDoc.exists()) {
                const userData = userDoc.data();
                console.log('User data:', userData);
                
                // Check if the user has roles defined
                if (userData.roles) {
                    console.log('User has roles defined:', userData.roles);
                    
                    // Return the roles object or array as is - we'll handle the format in hasRole
                    return userData.roles;
                }
                
                // If isAdmin is true but no roles object exists, create a roles object
                if (userData.isAdmin) {
                    console.log('User is admin via isAdmin flag');
                    return {
                        [ROLES.USER]: true,
                        [ROLES.ADMIN]: true
                    };
                }
                
                // Default to basic user role
                console.log('Defaulting to basic user role');
                return { [ROLES.USER]: true };
            }
            
            console.log('User document does not exist, defaulting to basic user role');
            return { [ROLES.USER]: true };
        } catch (error) {
            console.error('Error fetching user roles:', error);
            return { [ROLES.USER]: true };
        }
    };

    // Check if user has a specific role
    const hasRole = (role) => {
        console.log('Checking role:', role, 'User roles:', userRoles);
        
        // If no role is required, allow access
        if (!role) return true;
        
        // If user has no roles, deny access
        if (!userRoles || userRoles.length === 0) {
            console.log('User has no roles, denying access');
            return false;
        }
        
        // Check if the role is in the userRoles array
        if (Array.isArray(userRoles)) {
            const hasRoleInArray = userRoles.includes(role);
            console.log(`Role ${role} found in array: ${hasRoleInArray}`);
            return hasRoleInArray;
        }
        
        // If userRoles is an object (from Firestore), check if the role is true
        if (typeof userRoles === 'object' && !Array.isArray(userRoles)) {
            const hasRoleInObject = userRoles[role] === true;
            console.log(`Role ${role} found in object: ${hasRoleInObject}`);
            return hasRoleInObject;
        }
        
        // Default to false for safety
        return false;
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const roles = await getUserRoles(user.uid);
                setCurrentUser(user);
                setUserRoles(roles);
            } else {
                setCurrentUser(null);
                setUserRoles([]);
            }
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    // Create or update user document with roles
    const createUserDocument = async (user, additionalData = {}) => {
        if (!user) return;

        const userRef = doc(db, collections.USERS, user.uid);
        const snapshot = await getDoc(userRef);

        if (!snapshot.exists()) {
            const { email, displayName, photoURL } = user;
            const roles = [ROLES.USER];

            try {
                await setDoc(userRef, {
                    email,
                    displayName: displayName || additionalData.displayName,
                    photoURL,
                    roles,
                    createdAt: serverTimestamp(),
                    updatedAt: serverTimestamp(),
                    ...additionalData
                });
                return roles;
            } catch (error) {
                console.error('Error creating user document:', error);
                throw error;
            }
        } else {
            // Update existing user document
            const userData = snapshot.data();
            await setDoc(userRef, {
                ...userData,
                updatedAt: serverTimestamp(),
                ...additionalData
            }, { merge: true });
            return userData.roles || [ROLES.USER];
        }
    };

    // Sign up with email/password
    const signup = async (email, password, displayName) => {
        try {
            const { user } = await createUserWithEmailAndPassword(auth, email, password);
            await createUserDocument(user, { displayName });
            return user;
        } catch (error) {
            setError(error.message);
            throw error;
        }
    };

    // Sign in with email/password
    const login = async (email, password) => {
        try {
            const { user } = await signInWithEmailAndPassword(auth, email, password);
            return user;
        } catch (error) {
            setError(error.message);
            throw error;
        }
    };

    // Sign in with Google
    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            await createUserDocument(result.user);
            return result.user;
        } catch (error) {
            setError(error.message);
            throw error;
        }
    };

    // Sign out
    const logout = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            setError(error.message);
            throw error;
        }
    };

    // Reset password
    const resetPassword = async (email) => {
        try {
            await sendPasswordResetEmail(auth, email);
        } catch (error) {
            setError(error.message);
            throw error;
        }
    };

    // Request artist role
    const requestArtistRole = async (artistData) => {
        try {
            if (!currentUser) throw new Error('No user logged in');

            const requestRef = collection(db, collections.ARTIST_REQUESTS);
            await addDoc(requestRef, {
                userId: currentUser.uid,
                email: currentUser.email,
                status: 'pending',
                createdAt: serverTimestamp(),
                ...artistData
            });
        } catch (error) {
            setError(error.message);
            throw error;
        }
    };

    // Check if user has a pending artist request
    const checkArtistRequest = async () => {
        if (!currentUser) return null;

        const requestsRef = collection(db, collections.ARTIST_REQUESTS);
        const q = query(requestsRef, where('userId', '==', currentUser.uid));
        
        try {
            const querySnapshot = await getDocs(q);
            return !querySnapshot.empty;
        } catch (error) {
            console.error('Error checking artist request:', error);
            return null;
        }
    };

    const value = {
        currentUser,
        userRoles,
        loading,
        error,
        hasRole,
        signup,
        login,
        logout,
        resetPassword,
        signInWithGoogle,
        requestArtistRole,
        checkArtistRequest
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
