import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaBox, FaTruck, FaCheck, FaClock, FaMapMarkerAlt, FaCreditCard } from 'react-icons/fa';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { marketplaceApp } from '../../firebase.marketplace';
import { useAuth } from '../../contexts/AuthContext';
import useAdminCheck from '../../hooks/useAdminCheck';

const Container = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  color: white;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;

  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background: #4a90e2;
    margin: 1rem auto;
    border-radius: 2px;
  }
`;

const OrderGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const TrackingSection = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 2rem;
`;

const Timeline = styled.div`
  position: relative;
  margin: 2rem 0;
  padding-left: 2rem;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    background: rgba(255, 255, 255, 0.1);
  }
`;

const TimelineItem = styled.div`
  position: relative;
  padding-bottom: 2rem;

  &:last-child {
    padding-bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    left: -2rem;
    top: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: ${props => props.active ? '#4a90e2' : 'rgba(255, 255, 255, 0.1)'};
    border: 2px solid ${props => props.active ? '#4a90e2' : 'rgba(255, 255, 255, 0.1)'};
  }
`;

const TimelineContent = styled.div`
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  padding: 1rem;
`;

const TimelineTitle = styled.div`
  color: white;
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: ${props => props.active ? '#4a90e2' : '#888'};
  }
`;

const TimelineDate = styled.div`
  color: #888;
  font-size: 0.9rem;
`;

const OrderDetailsContainer = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 2rem;
`;

const DetailSection = styled.div`
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.h2`
  color: white;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: #4a90e2;
  }
`;

const DetailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

const DetailItem = styled.div`
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  padding: 1rem;
`;

const DetailLabel = styled.div`
  color: #888;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
`;

const DetailValue = styled.div`
  color: white;
`;

const ItemsList = styled.div`
  margin-top: 1rem;
`;

const Item = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ItemImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
`;

const ItemInfo = styled.div`
  flex: 1;
`;

const ItemName = styled.div`
  color: white;
  margin-bottom: 0.3rem;
`;

const ItemPrice = styled.div`
  color: #4a90e2;
  font-weight: 600;
`;

const ItemQuantity = styled.div`
  color: #888;
  font-size: 0.9rem;
`;

const OrderStatus = styled.div`
  padding: 0.5rem 1rem;
  background: ${props => {
    switch (props.status) {
      case 'completed':
        return 'rgba(76, 175, 80, 0.1)';
      case 'processing':
        return 'rgba(74, 144, 226, 0.1)';
      case 'cancelled':
        return 'rgba(244, 67, 54, 0.1)';
      default:
        return 'rgba(255, 255, 255, 0.1)';
    }
  }};
  color: ${props => {
    switch (props.status) {
      case 'completed':
        return '#4CAF50';
      case 'processing':
        return '#4a90e2';
      case 'cancelled':
        return '#f44336';
      default:
        return 'white';
    }
  }};
  border-radius: 4px;
  text-align: center;
  font-size: 0.9rem;
  text-transform: capitalize;
  margin-bottom: 1rem;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 4px solid #4a90e2;
  width: 50px;
  height: 50px;
  margin: 2rem auto;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const OrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();
  const { isAdmin, loading: adminCheckLoading } = useAdminCheck();

  useEffect(() => {
    const fetchOrder = async () => {
      if (!currentUser) {
        console.log("No authenticated user found");
        setLoading(false);
        return;
      }
      
      // Wait for admin check to complete
      if (adminCheckLoading) {
        return;
      }
      
      try {
        console.log(`Attempting to fetch order ${id} for user ${currentUser.uid} (isAdmin: ${isAdmin})`);
        const db = getFirestore(marketplaceApp);
        const orderRef = doc(db, 'orders', id);
        console.log(`Order reference path: ${orderRef.path}`);
        
        const orderDoc = await getDoc(orderRef).catch(error => {
          if (error.code === 'permission-denied') {
            console.error("Permission denied error:", error);
            throw new Error("You don't have permission to view this order. Please contact support if you believe this is an error.");
          }
          throw error;
        });
        
        if (!orderDoc) {
          setError("Unable to retrieve order details");
          setLoading(false);
          return;
        }
        
        console.log(`Order document exists: ${orderDoc.exists()}`);
        
        if (orderDoc.exists()) {
          const orderData = orderDoc.data();
          console.log(`Order data retrieved, userId: ${orderData.userId}`);
          
          // Check if the current user is the owner of the order or has admin privileges
          if (orderData.userId === currentUser.uid || isAdmin) {
            console.log(isAdmin ? "User is admin" : "User is the owner of this order");
            setOrder({
              id: orderDoc.id,
              ...orderData
            });
          } else {
            console.log("User is not the owner of this order and not an admin");
            setError("You do not have permission to view this order");
          }
        } else {
          console.log("Order document does not exist");
          setError("Order not found");
        }
      } catch (error) {
        console.error('Error fetching order:', error);
        setError(error.message || "An error occurred while fetching the order");
      } finally {
        setLoading(false);
      }
    };

    if (currentUser && !adminCheckLoading) {
      fetchOrder();
    } else if (!currentUser) {
      setLoading(false);
    }
  }, [id, currentUser, isAdmin, adminCheckLoading]);

  if (loading || adminCheckLoading) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <div style={{ textAlign: 'center', color: 'white', padding: '2rem' }}>
          <h2>Error Loading Order</h2>
          <p>{error}</p>
          <p>Please try again later or contact support if the problem persists.</p>
        </div>
      </Container>
    );
  }

  if (!currentUser) {
    return (
      <Container>
        <div style={{ textAlign: 'center', color: 'white', padding: '2rem' }}>
          <h2>Authentication Required</h2>
          <p>Please sign in to view your order details.</p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate('/login')}
            style={{
              background: '#4a90e2',
              color: 'white',
              border: 'none',
              padding: '0.75rem 1.5rem',
              borderRadius: '8px',
              cursor: 'pointer',
              marginTop: '1rem',
              fontSize: '1rem'
            }}
          >
            Sign In
          </motion.button>
        </div>
      </Container>
    );
  }

  if (!order) {
    return (
      <Container>
        <div>Order not found</div>
      </Container>
    );
  }

  const trackingSteps = [
    {
      title: 'Order Placed',
      icon: FaBox,
      date: new Date(order.createdAt.toDate()).toLocaleString(),
      active: true
    },
    {
      title: 'Processing',
      icon: FaClock,
      date: order.status === 'processing' ? 'In Progress' : '-',
      active: order.status === 'processing' || order.status === 'completed'
    },
    {
      title: 'Shipped',
      icon: FaTruck,
      date: order.shippedAt ? new Date(order.shippedAt.toDate()).toLocaleString() : '-',
      active: order.status === 'shipped' || order.status === 'completed'
    },
    {
      title: 'Delivered',
      icon: FaCheck,
      date: order.deliveredAt ? new Date(order.deliveredAt.toDate()).toLocaleString() : '-',
      active: order.status === 'completed'
    }
  ];

  return (
    <Container>
      <Header>
        <Title>Order Details</Title>
      </Header>

      <OrderGrid>
        <TrackingSection>
          <OrderStatus status={order.status}>{order.status}</OrderStatus>
          <Timeline>
            {trackingSteps.map((step, index) => (
              <TimelineItem key={index} active={step.active}>
                <TimelineContent>
                  <TimelineTitle active={step.active}>
                    <step.icon /> {step.title}
                  </TimelineTitle>
                  <TimelineDate>{step.date}</TimelineDate>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </TrackingSection>

        <OrderDetailsContainer>
          <DetailSection>
            <SectionTitle>
              <FaBox /> Order Information
            </SectionTitle>
            <DetailGrid>
              <DetailItem>
                <DetailLabel>Order ID</DetailLabel>
                <DetailValue>#{order.id}</DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailLabel>Order Date</DetailLabel>
                <DetailValue>
                  {new Date(order.createdAt.toDate()).toLocaleDateString()}
                </DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailLabel>Total Amount</DetailLabel>
                <DetailValue>${order.total.toFixed(2)}</DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailLabel>Payment Method</DetailLabel>
                <DetailValue>
                  {order.paymentMethod === 'card' ? 'Credit Card' : 'Mobile Money'}
                </DetailValue>
              </DetailItem>
            </DetailGrid>
          </DetailSection>

          <DetailSection>
            <SectionTitle>
              <FaMapMarkerAlt /> Shipping Information
            </SectionTitle>
            <DetailItem>
              <DetailLabel>Shipping Address</DetailLabel>
              <DetailValue>
                {`${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`}
                <br />
                {order.shippingAddress.address}
                <br />
                {`${order.shippingAddress.city}, ${order.shippingAddress.country}`}
              </DetailValue>
            </DetailItem>
          </DetailSection>

          <DetailSection>
            <SectionTitle>
              <FaBox /> Order Items
            </SectionTitle>
            <ItemsList>
              {order.items.map((item, index) => (
                <Item key={`${order.id}-${index}`}>
                  <ItemImage src={item.imageUrl} alt={item.title} />
                  <ItemInfo>
                    <ItemName>{item.title}</ItemName>
                    <ItemPrice>${(item.price * item.quantity).toFixed(2)}</ItemPrice>
                    <ItemQuantity>Quantity: {item.quantity}</ItemQuantity>
                  </ItemInfo>
                </Item>
              ))}
            </ItemsList>
          </DetailSection>
        </OrderDetailsContainer>
      </OrderGrid>
    </Container>
  );
};

export default OrderDetails;
