// RecentSlides.js
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { db } from '../../../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { setRecents } from '../../../../features/recent/recentSlice';
import FeaturedSlides from './FeaturedSlides';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  backdrop-filter: blur(10px);
`;

const ErrorContainer = styled(motion.div)`
  padding: 20px;
  background: rgba(255, 0, 0, 0.1);
  border-radius: 12px;
  margin: 20px 0;
  text-align: center;
  color: #ff6b6b;
  border: 1px solid rgba(255, 0, 0, 0.2);
`;

const SectionContainer = styled(motion.div)`
  margin: 20px 0;
  padding: 20px;
  background: linear-gradient(145deg, #1a1e2e 0%, #0d1017 100%);
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
`;

const SectionTitle = styled.h2`
  color: #fff;
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
  
  &::after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #4b7bec, #3867d6);
    margin: 10px auto;
    border-radius: 2px;
  }
`;

const RecentSlides = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRecents = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const recentsRef = collection(db, 'recents');
        const snapshot = await getDocs(recentsRef);
        
        if (snapshot.empty) {
          console.log('No recents found');
          setError('No recent songs available');
          return;
        }

        const recents = snapshot.docs.map(doc => ({
          id: doc.id,
          title: doc.data().title || '',
          url: doc.data().url || '',
          image: doc.data().image || '',
          timestamp: doc.data().timestamp || null
        }));
        
        console.log('Fetched recents:', recents.length);
        dispatch(setRecents(recents));
        
      } catch (error) {
        console.error('Error fetching recents:', error);
        setError('Failed to load recent songs');
      } finally {
        setLoading(false);
      }
    };

    fetchRecents();
  }, [dispatch]);

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress style={{ color: '#4b7bec' }} />
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <ErrorContainer
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {error}
      </ErrorContainer>
    );
  }

  return (
    <SectionContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <SectionTitle>Latest Music</SectionTitle>
      <FeaturedSlides />
    </SectionContainer>
  );
};

export default RecentSlides;
