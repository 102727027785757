import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import styled from 'styled-components';
import { db } from '../../firebase';
import { collection, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import Nav3 from '../Navs/SideView/Nav3';

const DashboardContainer = styled.div`
  min-height: 100vh;
  background-color: #030617;
  color: white;
  padding: 2rem;
`;

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const Card = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 1.5rem;
`;

const Form = styled.form`
  max-width: 500px;
  margin: 2rem auto;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  color: white;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  color: white;
  min-height: 100px;
`;

const Button = styled.button`
  background: #4a90e2;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background: #357abd;
  }
`;

const ArtistDashboard = () => {
  const { user } = useAuth();
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({
    name: '',
    description: '',
    price: '',
    category: 'music',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      fetchProducts();
    }
  }, [user]);

  const fetchProducts = async () => {
    try {
      const productsRef = collection(db, 'products');
      const q = query(productsRef, where('artistId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      
      const productsData = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        $id: doc.id
      }));
      setProducts(productsData);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const productsRef = collection(db, 'products');
      const productData = {
        ...newProduct,
        price: parseFloat(newProduct.price),
        artistId: user.uid,
        status: 'active',
        createdAt: serverTimestamp()
      };

      await addDoc(productsRef, productData);
      
      // Reset form
      setNewProduct({
        name: '',
        description: '',
        price: '',
        category: 'music',
      });
      
      // Refresh products list
      fetchProducts();
    } catch (error) {
      console.error('Error creating product:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setNewProduct({
      ...newProduct,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <DashboardContainer>
      <Nav3 />
      <h1>Artist Dashboard</h1>
      <p>Welcome, {user?.name}</p>

      <Form onSubmit={handleSubmit}>
        <h2>Add New Product</h2>
        <Input
          type="text"
          name="name"
          placeholder="Product Name"
          value={newProduct.name}
          onChange={handleChange}
          required
        />
        
        <TextArea
          name="description"
          placeholder="Product Description"
          value={newProduct.description}
          onChange={handleChange}
          required
        />
        
        <Input
          type="number"
          name="price"
          placeholder="Price (UGX)"
          value={newProduct.price}
          onChange={handleChange}
          required
        />
        
        <select
          name="category"
          value={newProduct.category}
          onChange={handleChange}
          style={{
            width: '100%',
            padding: '0.8rem',
            margin: '0.5rem 0',
            background: 'rgba(255, 255, 255, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.2)',
            borderRadius: '5px',
            color: 'white',
          }}
        >
          <option value="music">Music</option>
          <option value="merchandise">Merchandise</option>
          <option value="art">Art</option>
        </select>
        
        <Button type="submit" disabled={loading}>
          {loading ? 'Adding...' : 'Add Product'}
        </Button>
      </Form>

      <h2>Your Products</h2>
      <DashboardGrid>
        {products.map(product => (
          <Card key={product.$id}>
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <p>Price: UGX {product.price.toLocaleString()}</p>
            <p>Category: {product.category}</p>
            <p>Status: {product.status}</p>
          </Card>
        ))}
      </DashboardGrid>
    </DashboardContainer>
  );
};

export default ArtistDashboard;
