import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  min-height: 100vh;
  background-color: #030617;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const FormContainer = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
`;

const Title = styled.h2`
  color: white;
  text-align: center;
  margin-bottom: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  color: white;
  font-size: 0.9rem;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    outline: none;
    border-color: #4a90e2;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  color: white;
  font-size: 0.9rem;
  min-height: 120px;
  resize: vertical;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    outline: none;
    border-color: #4a90e2;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 1rem;

  &:hover {
    background: #357abd;
  }

  &:disabled {
    background: #666;
    cursor: not-allowed;
  }
`;

const Error = styled.div`
  color: #ff6b6b;
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
`;

const Message = styled.div`
  color: #4a90e2;
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
`;

const BecomeArtist = () => {
  const [formData, setFormData] = useState({
    artistName: '',
    genre: '',
    bio: '',
    socialLinks: '',
    experience: ''
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  
  const { requestArtistRole, error } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      await requestArtistRole({
        ...formData,
        requestType: 'artist_application'
      });
      setMessage('Your artist application has been submitted successfully. We will review it and get back to you soon.');
      setTimeout(() => navigate('/'), 3000);
    } catch (error) {
      console.error('Error submitting artist application:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <FormContainer>
        <Title>Become an Artist</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="artistName"
            placeholder="Artist/Band Name"
            value={formData.artistName}
            onChange={handleChange}
            required
          />
          
          <Input
            type="text"
            name="genre"
            placeholder="Music Genre(s)"
            value={formData.genre}
            onChange={handleChange}
            required
          />

          <TextArea
            name="bio"
            placeholder="Tell us about yourself and your music..."
            value={formData.bio}
            onChange={handleChange}
            required
          />

          <Input
            type="text"
            name="socialLinks"
            placeholder="Social Media Links (Instagram, YouTube, etc.)"
            value={formData.socialLinks}
            onChange={handleChange}
          />

          <TextArea
            name="experience"
            placeholder="Tell us about your music experience and achievements..."
            value={formData.experience}
            onChange={handleChange}
            required
          />

          {error && <Error>{error}</Error>}
          {message && <Message>{message}</Message>}

          <Button type="submit" disabled={loading}>
            {loading ? 'Submitting Application...' : 'Submit Application'}
          </Button>
        </Form>
      </FormContainer>
    </Container>
  );
};

export default BecomeArtist;
