import { collection, doc, setDoc, addDoc, updateDoc, deleteDoc, serverTimestamp, increment, getDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Marketplace Collections
export const COLLECTIONS = {
  ARTISTS: 'artists',
  PRODUCTS: 'products',
  ORDERS: 'orders',
  STREAMING_EVENTS: 'streamEvents',
  TICKETS: 'tickets',
  REVIEWS: 'reviews'
};

// Artist Management
export const createArtistProfile = async (userId, artistData) => {
  try {
    const artistRef = doc(db, COLLECTIONS.ARTISTS, userId);
    await setDoc(artistRef, {
      ...artistData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      status: 'active',
      totalSales: 0,
      rating: 0,
      reviewCount: 0
    });
    return { success: true, artistId: userId };
  } catch (error) {
    console.error('Error creating artist profile:', error);
    throw error;
  }
};

// Product Management
export const createProduct = async (artistId, productData, imageFile) => {
  try {
    // Upload image first
    const imageRef = ref(storage, `products/${artistId}/${imageFile.name}`);
    await uploadBytes(imageRef, imageFile);
    const imageUrl = await getDownloadURL(imageRef);

    // Create product document
    const productRef = collection(db, COLLECTIONS.PRODUCTS);
    const newProduct = await addDoc(productRef, {
      ...productData,
      artistId,
      imageUrl,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      status: 'active',
      sales: 0,
      rating: 0,
      reviewCount: 0
    });

    return { success: true, productId: newProduct.id };
  } catch (error) {
    console.error('Error creating product:', error);
    throw error;
  }
};

// Order Management
export const createOrder = async (userId, orderData) => {
  try {
    const orderRef = collection(db, COLLECTIONS.ORDERS);
    const newOrder = await addDoc(orderRef, {
      ...orderData,
      userId,
      status: 'pending',
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    // Update product sales count
    const productRef = doc(db, COLLECTIONS.PRODUCTS, orderData.productId);
    await updateDoc(productRef, {
      sales: increment(1),
      updatedAt: serverTimestamp()
    });

    return { success: true, orderId: newOrder.id };
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

// Streaming Events Management
export const createStreamingEvent = async (artistId, eventData, coverImage) => {
  try {
    // Upload cover image
    const imageRef = ref(storage, `events/${artistId}/${coverImage.name}`);
    await uploadBytes(imageRef, coverImage);
    const imageUrl = await getDownloadURL(imageRef);

    // Create event document
    const eventRef = collection(db, COLLECTIONS.STREAMING_EVENTS);
    const newEvent = await addDoc(eventRef, {
      ...eventData,
      artistId,
      imageUrl,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      status: 'upcoming',
      ticketsSold: 0,
      maxAttendees: eventData.maxAttendees || 1000,
      streamUrl: null // Will be updated before event starts
    });

    return { success: true, eventId: newEvent.id };
  } catch (error) {
    console.error('Error creating streaming event:', error);
    throw error;
  }
};

// Ticket Management
export const purchaseTicket = async (userId, eventId, ticketData) => {
  try {
    // Check if event has available tickets
    const eventRef = doc(db, COLLECTIONS.STREAMING_EVENTS, eventId);
    const eventDoc = await getDoc(eventRef);
    
    if (!eventDoc.exists()) {
      throw new Error('Event not found');
    }

    const eventData = eventDoc.data();
    if (eventData.ticketsSold >= eventData.maxAttendees) {
      throw new Error('Event is sold out');
    }

    // Create ticket
    const ticketRef = collection(db, COLLECTIONS.TICKETS);
    const newTicket = await addDoc(ticketRef, {
      userId,
      eventId,
      ...ticketData,
      status: 'active',
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      accessToken: generateAccessToken() // Function to generate unique access token
    });

    // Update event ticket count
    await updateDoc(eventRef, {
      ticketsSold: increment(1),
      updatedAt: serverTimestamp()
    });

    return { success: true, ticketId: newTicket.id };
  } catch (error) {
    console.error('Error purchasing ticket:', error);
    throw error;
  }
};

// Review Management
export const createReview = async (userId, targetId, reviewData, type = 'product') => {
  try {
    const reviewRef = collection(db, COLLECTIONS.REVIEWS);
    const newReview = await addDoc(reviewRef, {
      userId,
      targetId,
      type, // 'product' or 'event'
      ...reviewData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    // Update target's rating
    const targetRef = doc(db, type === 'product' ? COLLECTIONS.PRODUCTS : COLLECTIONS.STREAMING_EVENTS, targetId);
    await updateDoc(targetRef, {
      rating: reviewData.rating, // You might want to calculate average
      reviewCount: increment(1),
      updatedAt: serverTimestamp()
    });

    return { success: true, reviewId: newReview.id };
  } catch (error) {
    console.error('Error creating review:', error);
    throw error;
  }
};

// Helper function to generate access token
const generateAccessToken = () => {
  return Math.random().toString(36).substr(2) + Date.now().toString(36);
};
