import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTrash, FaMinus, FaPlus, FaShoppingCart } from 'react-icons/fa';
import { useCart } from '../../context/CartContext';

const Container = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  color: white;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;

  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background: #4a90e2;
    margin: 1rem auto;
    border-radius: 2px;
  }
`;

const CartGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const CartItems = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  overflow: hidden;
`;

const CartItem = styled(motion.div)`
  display: grid;
  grid-template-columns: auto 2fr 1fr auto;
  gap: 1rem;
  padding: 1.5rem;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 600px) {
    grid-template-columns: auto 1fr;
    gap: 1rem;
  }
`;

const ItemImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
`;

const ItemInfo = styled.div`
  @media (max-width: 600px) {
    grid-column: 2;
  }
`;

const ItemTitle = styled.h3`
  color: white;
  margin-bottom: 0.5rem;
`;

const ItemType = styled.div`
  color: #888;
  font-size: 0.9rem;
`;

const ItemPrice = styled.div`
  color: #4a90e2;
  font-weight: 600;
  font-size: 1.1rem;

  @media (max-width: 600px) {
    grid-column: 2;
  }
`;

const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 600px) {
    grid-column: 2;
  }
`;

const QuantityButton = styled.button`
  background: rgba(74, 144, 226, 0.1);
  color: #4a90e2;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(74, 144, 226, 0.2);
  }
`;

const Quantity = styled.span`
  color: white;
  min-width: 30px;
  text-align: center;
`;

const RemoveButton = styled.button`
  background: transparent;
  color: #ff4d4d;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;

  &:hover {
    color: #ff6666;
  }

  @media (max-width: 600px) {
    grid-column: 1;
    grid-row: 1;
  }
`;

const Summary = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 1.5rem;
  height: fit-content;
`;

const SummaryTitle = styled.h2`
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
`;

const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${props => props.total ? 'white' : '#888'};
  font-size: ${props => props.total ? '1.2rem' : '1rem'};
  font-weight: ${props => props.total ? '600' : '400'};
  margin-bottom: 1rem;
  padding-bottom: ${props => props.total ? '1rem' : '0'};
  border-bottom: ${props => props.total ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'};
`;

const CheckoutButton = styled(motion.button)`
  width: 100%;
  padding: 1rem;
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    background: #357abd;
  }
`;

const EmptyCart = styled.div`
  text-align: center;
  padding: 4rem 2rem;
  color: #888;
`;

const EmptyCartIcon = styled.div`
  font-size: 3rem;
  color: #4a90e2;
  margin-bottom: 1rem;
`;

const Cart = () => {
  const navigate = useNavigate();
  const { cart, removeFromCart, updateQuantity, getCartTotal } = useCart();

  if (cart.items.length === 0) {
    return (
      <Container>
        <Header>
          <Title>Shopping Cart</Title>
        </Header>
        <EmptyCart>
          <EmptyCartIcon>
            <FaShoppingCart />
          </EmptyCartIcon>
          <p>Your cart is empty</p>
        </EmptyCart>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Title>Shopping Cart</Title>
      </Header>
      <CartGrid>
        <CartItems>
          <AnimatePresence>
            {cart.items.map(item => (
              <CartItem
                key={`${item.id}-${item.type}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
              >
                <ItemImage src={item.imageUrl} alt={item.title} />
                <ItemInfo>
                  <ItemTitle>{item.title}</ItemTitle>
                  <ItemType>{item.type === 'product' ? 'Product' : 'Event Ticket'}</ItemType>
                </ItemInfo>
                <ItemPrice>${(item.price * item.quantity).toFixed(2)}</ItemPrice>
                <QuantityControls>
                  <QuantityButton
                    onClick={() => updateQuantity({
                      id: item.id,
                      type: item.type,
                      quantity: Math.max(1, item.quantity - 1)
                    })}
                  >
                    <FaMinus />
                  </QuantityButton>
                  <Quantity>{item.quantity}</Quantity>
                  <QuantityButton
                    onClick={() => updateQuantity({
                      id: item.id,
                      type: item.type,
                      quantity: item.quantity + 1
                    })}
                  >
                    <FaPlus />
                  </QuantityButton>
                  <RemoveButton
                    onClick={() => removeFromCart({
                      id: item.id,
                      type: item.type
                    })}
                  >
                    <FaTrash />
                  </RemoveButton>
                </QuantityControls>
              </CartItem>
            ))}
          </AnimatePresence>
        </CartItems>

        <Summary>
          <SummaryTitle>Order Summary</SummaryTitle>
          <SummaryItem>
            <span>Subtotal</span>
            <span>${getCartTotal().toFixed(2)}</span>
          </SummaryItem>
          <SummaryItem>
            <span>Shipping</span>
            <span>Free</span>
          </SummaryItem>
          <SummaryItem total>
            <span>Total</span>
            <span>${getCartTotal().toFixed(2)}</span>
          </SummaryItem>
          <CheckoutButton
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => navigate('/checkout')}
          >
            Proceed to Checkout
          </CheckoutButton>
        </Summary>
      </CartGrid>
    </Container>
  );
};

export default Cart;
