import React from 'react';
import useAdsense from '../hooks/useAdsense';

const GoogleAd3 = () => {
  const adRef = useAdsense();

  return (
    <div className="Ad" ref={adRef}>
      <ins
        className="adsbygoogle"
        style={{ display: 'block', background: "#030617" }}
        data-ad-client="ca-pub-2968429081843062"
        data-ad-slot="8703718386"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </div>
  );
};

export default GoogleAd3;