import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import { ROLES } from '../../firebase.marketplace';

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const AuthButton = styled.button`
  background: ${props => props.primary ? '#4a90e2' : 'transparent'};
  color: white;
  border: ${props => props.primary ? 'none' : '1px solid #4a90e2'};
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;

  &:hover {
    background: ${props => props.primary ? '#357abd' : 'rgba(74, 144, 226, 0.1)'};
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const UserName = styled.span`
  color: white;
  font-size: 0.9rem;
`;

const RoleBadge = styled.span`
  background-color: ${props => props.isAdmin ? '#ff5722' : '#4caf50'};
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 10px;
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const AuthButtons = () => {
  const { currentUser, hasRole, logout, loading } = useAuth();
  const navigate = useNavigate();
  
  console.log('Current user:', currentUser);
  console.log('Is admin:', hasRole(ROLES.ADMIN));
  console.log('Is artist:', hasRole(ROLES.ARTIST));

  const handleSignIn = () => {
    navigate('/marketplace/auth/signin');
  };

  const handleSignUp = () => {
    navigate('/marketplace/auth/signup');
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/marketplace');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  if (loading) {
    return <ButtonsContainer>Loading...</ButtonsContainer>;
  }

  if (!currentUser) {
    return (
      <ButtonsContainer>
        <AuthButton onClick={handleSignIn}>
          Sign In
        </AuthButton>
        <AuthButton primary onClick={handleSignUp}>
          Join as Artist
        </AuthButton>
      </ButtonsContainer>
    );
  }

  const isAdmin = hasRole(ROLES.ADMIN);
  const isArtist = hasRole(ROLES.ARTIST);

  return (
    <ButtonsContainer>
      <UserInfo>
        <UserName>{currentUser.email}</UserName>
        {isAdmin && <RoleBadge isAdmin>Admin</RoleBadge>}
        {isArtist && <RoleBadge>Artist</RoleBadge>}
        
        {isAdmin && (
          <>
            <AuthButton onClick={() => navigate('/admin-panel')}>
              Admin Panel
            </AuthButton>
            <AuthButton onClick={() => navigate('/marketplace/admin/artist-requests')}>
              Artist Requests
            </AuthButton>
          </>
        )}
        
        {isArtist && (
          <AuthButton onClick={() => navigate('/artist-dashboard')}>
            Artist Dashboard
          </AuthButton>
        )}
        
        <AuthButton onClick={() => navigate('/marketplace/orders')}>
          My Orders
        </AuthButton>
        
        <AuthButton onClick={handleLogout}>
          Logout
        </AuthButton>
      </UserInfo>
    </ButtonsContainer>
  );
};

export default AuthButtons;
