import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  products: [],
  artists: [],
  orders: [],
  loading: false,
  error: null,
  currentArtist: null,
};

export const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setArtists: (state, action) => {
      state.artists = action.payload;
    },
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setCurrentArtist: (state, action) => {
      state.currentArtist = action.payload;
    },
  },
});

export const {
  setProducts,
  setArtists,
  setOrders,
  setLoading,
  setError,
  setCurrentArtist,
} = marketplaceSlice.actions;

export default marketplaceSlice.reducer;
