import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import { BsCalendarEvent, BsClock } from 'react-icons/bs';
import { FaTicketAlt } from 'react-icons/fa';

const Container = styled.div`
  padding: 2rem;
  max-width: 1000px;
  margin: 0 auto;
`;

const EventCard = styled(motion.div)`
  background: linear-gradient(145deg, #1a1e2e 0%, #0d1017 100%);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
`;

const EventImage = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
`;

const EventContent = styled.div`
  padding: 2rem;
`;

const EventTitle = styled.h1`
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
`;

const EventInfo = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4b7bec;
  font-size: 1rem;

  svg {
    font-size: 1.2rem;
  }
`;

const EventDescription = styled.p`
  color: #a0a0a0;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const PurchaseSection = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const PriceTag = styled.div`
  font-size: 2rem;
  color: #4b7bec;
  font-weight: 600;
`;

const PurchaseButton = styled.button`
  flex: 1;
  padding: 1rem;
  background: linear-gradient(90deg, #4b7bec, #3867d6);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.9;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
`;

const ErrorContainer = styled(motion.div)`
  padding: 2rem;
  background: rgba(255, 0, 0, 0.1);
  border-radius: 12px;
  margin: 2rem auto;
  max-width: 600px;
  text-align: center;
  color: #ff6b6b;
  border: 1px solid rgba(255, 0, 0, 0.2);
`;

const EventDetails = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const eventRef = doc(db, 'streamEvents', id);
        const eventDoc = await getDoc(eventRef);
        
        if (!eventDoc.exists()) {
          setError('Event not found');
          return;
        }

        setEvent({
          id: eventDoc.id,
          ...eventDoc.data()
        });
      } catch (error) {
        console.error('Error fetching event details:', error);
        setError('Failed to load event details');
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [id]);

  const handlePurchase = async () => {
    // TODO: Implement iotec payment integration
    try {
      // 1. Create payment session
      // 2. Handle payment confirmation
      // 3. Send access credentials via email
      // 4. Store transaction in Firestore
      alert('Payment integration coming soon!');
    } catch (error) {
      console.error('Payment error:', error);
    }
  };

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress style={{ color: '#4b7bec' }} />
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <ErrorContainer
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {error}
      </ErrorContainer>
    );
  }

  return (
    <Container>
      <EventCard
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <EventImage src={event.image} alt={event.title} />
        <EventContent>
          <EventTitle>{event.title}</EventTitle>
          <EventInfo>
            <InfoItem>
              <BsCalendarEvent />
              <span>{new Date(event.date).toLocaleDateString()}</span>
            </InfoItem>
            <InfoItem>
              <BsClock />
              <span>{event.time}</span>
            </InfoItem>
            <InfoItem>
              <FaTicketAlt />
              <span>${event.price}</span>
            </InfoItem>
          </EventInfo>
          <EventDescription>{event.description}</EventDescription>
          <PurchaseSection>
            <PriceTag>${event.price}</PriceTag>
            <PurchaseButton onClick={handlePurchase}>
              Purchase Access
            </PurchaseButton>
          </PurchaseSection>
        </EventContent>
      </EventCard>
    </Container>
  );
};

export default EventDetails;
