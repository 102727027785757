import {React, useEffect} from 'react'
import { db } from '../../firebase'
import { collection, onSnapshot } from 'firebase/firestore'
import {useDispatch} from "react-redux"
import {setRecents} from "../../features/recent/recentSlice"
import RecentlyAdded from '../audio/Songs/RecentlyAdded'
import GoogleAd2 from '../GoogleAd2';
import GoogleAd from '../GoogleAd';

function RecentlyAddedHitsPage2() {
  const dispatch = useDispatch();

  useEffect(() => {
    const collectionRef = collection(db, "recents");
    
    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        const tempRecents = snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
        });
        console.log(tempRecents);
        dispatch(setRecents(tempRecents));
    }, (error) => {
        console.error('Error fetching recents:', error);
    });

    return () => unsubscribe();
  }, [dispatch])


    return (
        <>
         <GoogleAd2 />

         <RecentlyAdded />
         <GoogleAd />
        </>
    )
}

export default RecentlyAddedHitsPage2;
