import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { db as marketplaceDb } from '../../firebase.marketplace';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  doc, 
  getDoc, 
  updateDoc 
} from 'firebase/firestore';
import { Button, Card, Container, Row, Col, Badge, Alert, Spinner } from 'react-bootstrap';
import { ROLES } from '../../firebase.marketplace';

const AdminPanel = () => {
  const { currentUser, userRoles, hasRole } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [artistRequests, setArtistRequests] = useState([]);
  const [processingIds, setProcessingIds] = useState([]);
  const [success, setSuccess] = useState('');

  // Check if user is admin
  const isAdmin = hasRole(ROLES.ADMIN);
  
  console.log('AdminPanel - Current user:', currentUser?.email);
  console.log('AdminPanel - User roles:', userRoles);
  console.log('AdminPanel - Is admin:', isAdmin);

  useEffect(() => {
    if (!currentUser || !isAdmin) {
      setLoading(false);
      setError('You do not have permission to access this page.');
      return;
    }

    fetchArtistRequests();
  }, [currentUser, isAdmin]);

  const fetchArtistRequests = async () => {
    setLoading(true);
    setError('');
    setSuccess('');
    
    try {
      // Get all pending artist requests
      const requestsRef = collection(marketplaceDb, 'artist_requests');
      const requestsQuery = query(requestsRef, where('status', '==', 'pending'));
      const requestsSnapshot = await getDocs(requestsQuery);
      
      if (requestsSnapshot.empty) {
        setArtistRequests([]);
        setLoading(false);
        return;
      }
      
      // For each request, get the user details
      const requests = [];
      
      for (const requestDoc of requestsSnapshot.docs) {
        const requestData = requestDoc.data();
        const userId = requestData.userId;
        
        // Get user details
        const userDoc = await getDoc(doc(marketplaceDb, 'users', userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          
          requests.push({
            requestId: requestDoc.id,
            userId: userId,
            email: userData.email || 'Unknown email',
            displayName: userData.displayName || 'Unknown user',
            timestamp: requestData.timestamp,
            data: requestData
          });
        }
      }
      
      // Sort by timestamp (newest first)
      requests.sort((a, b) => {
        const dateA = a.timestamp ? new Date(a.timestamp) : new Date(0);
        const dateB = b.timestamp ? new Date(b.timestamp) : new Date(0);
        return dateB - dateA;
      });
      
      setArtistRequests(requests);
      
    } catch (err) {
      console.error('Error fetching artist requests:', err);
      setError('Failed to load artist requests. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const approveArtistRequest = async (request) => {
    setProcessingIds(prev => [...prev, request.requestId]);
    setError('');
    setSuccess('');
    
    try {
      // Update the artist request status to approved
      await updateDoc(doc(marketplaceDb, 'artist_requests', request.requestId), {
        status: 'approved',
        approvedAt: new Date().toISOString(),
        approvedBy: currentUser.uid
      });
      
      // Update the user's roles to include artist
      await updateDoc(doc(marketplaceDb, 'users', request.userId), {
        'roles.artist': true,
        updatedAt: new Date().toISOString()
      });
      
      setSuccess(`Artist request for ${request.email} has been approved.`);
      
      // Refresh the list
      fetchArtistRequests();
      
    } catch (err) {
      console.error('Error approving artist request:', err);
      setError(`Failed to approve artist request for ${request.email}. Please try again.`);
    } finally {
      setProcessingIds(prev => prev.filter(id => id !== request.requestId));
    }
  };

  const rejectArtistRequest = async (request) => {
    setProcessingIds(prev => [...prev, request.requestId]);
    setError('');
    setSuccess('');
    
    try {
      // Update the artist request status to rejected
      await updateDoc(doc(marketplaceDb, 'artist_requests', request.requestId), {
        status: 'rejected',
        rejectedAt: new Date().toISOString(),
        rejectedBy: currentUser.uid
      });
      
      setSuccess(`Artist request for ${request.email} has been rejected.`);
      
      // Refresh the list
      fetchArtistRequests();
      
    } catch (err) {
      console.error('Error rejecting artist request:', err);
      setError(`Failed to reject artist request for ${request.email}. Please try again.`);
    } finally {
      setProcessingIds(prev => prev.filter(id => id !== request.requestId));
    }
  };

  const makeUserAdmin = async (userId, email) => {
    setProcessingIds(prev => [...prev, userId]);
    setError('');
    setSuccess('');
    
    try {
      // Update the user's roles to include admin
      await updateDoc(doc(marketplaceDb, 'users', userId), {
        'roles.admin': true,
        'roles.user': true,
        isAdmin: true,
        updatedAt: new Date().toISOString()
      });
      
      setSuccess(`User ${email} has been made an admin.`);
      
    } catch (err) {
      console.error('Error making user admin:', err);
      setError(`Failed to make ${email} an admin. Please try again.`);
    } finally {
      setProcessingIds(prev => prev.filter(id => id !== userId));
    }
  };

  // If user is not admin, show access denied
  if (!isAdmin && !loading) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">
          <Alert.Heading>Access Denied</Alert.Heading>
          <p>You do not have permission to access this page.</p>
        </Alert>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <h1>Admin Panel</h1>
      <Badge bg="danger" className="mb-3">Admin Access</Badge>
      
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      
      <Card className="mb-4">
        <Card.Header as="h5">
          <div className="d-flex justify-content-between align-items-center">
            <span>Pending Artist Requests</span>
            <Button 
              variant="outline-primary" 
              size="sm" 
              onClick={fetchArtistRequests}
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : 'Refresh'}
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {loading ? (
            <div className="text-center py-4">
              <Spinner animation="border" />
              <p className="mt-2">Loading artist requests...</p>
            </div>
          ) : artistRequests.length === 0 ? (
            <Alert variant="info">No pending artist requests found.</Alert>
          ) : (
            <Row>
              {artistRequests.map(request => (
                <Col md={6} key={request.requestId} className="mb-3">
                  <Card>
                    <Card.Body>
                      <Card.Title>{request.displayName}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">{request.email}</Card.Subtitle>
                      <Card.Text>
                        Requested: {request.timestamp ? new Date(request.timestamp).toLocaleString() : 'Unknown date'}
                      </Card.Text>
                      <div className="d-flex justify-content-between">
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => approveArtistRequest(request)}
                          disabled={processingIds.includes(request.requestId)}
                        >
                          {processingIds.includes(request.requestId) ? (
                            <><Spinner animation="border" size="sm" /> Approving...</>
                          ) : 'Approve'}
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => rejectArtistRequest(request)}
                          disabled={processingIds.includes(request.requestId)}
                        >
                          {processingIds.includes(request.requestId) ? (
                            <><Spinner animation="border" size="sm" /> Rejecting...</>
                          ) : 'Reject'}
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </Card.Body>
      </Card>
      
      <Card className="mb-4">
        <Card.Header as="h5">Make User Admin</Card.Header>
        <Card.Body>
          <p>To make Wilbert Kihembo an admin, click the button below:</p>
          <Button
            variant="warning"
            onClick={() => makeUserAdmin('s8X05nmryAfzYZEAMfbc9g1NIuJ3', 'wilbert@kihembo.com')}
            disabled={processingIds.includes('s8X05nmryAfzYZEAMfbc9g1NIuJ3')}
          >
            {processingIds.includes('s8X05nmryAfzYZEAMfbc9g1NIuJ3') ? (
              <><Spinner animation="border" size="sm" /> Processing...</>
            ) : 'Make Wilbert Admin'}
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AdminPanel;
