import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { motion, AnimatePresence } from 'framer-motion';
import { BsCalendarEvent, BsClock } from 'react-icons/bs';
import { FaTicketAlt, FaSearch, FaFilter } from 'react-icons/fa';
import { CircularProgress } from '@mui/material';

const StreamingEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all'); // all, upcoming, past

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsRef = collection(db, 'streamEvents');
        const snapshot = await getDocs(eventsRef);
        const eventsList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setEvents(eventsList);
        setError(null);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Failed to load events. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const filteredEvents = events.filter(event => {
    const matchesSearch = event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         event.description.toLowerCase().includes(searchTerm.toLowerCase());
    const eventDate = new Date(event.date);
    const now = new Date();

    switch (filter) {
      case 'upcoming':
        return matchesSearch && eventDate > now;
      case 'past':
        return matchesSearch && eventDate < now;
      default:
        return matchesSearch;
    }
  });

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress style={{ color: '#4b7bec' }} />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <Header>
        <Title>Live Events</Title>
        <SearchFilterContainer>
          <SearchBar>
            <SearchIcon>
              <FaSearch />
            </SearchIcon>
            <SearchInput
              type="text"
              placeholder="Search events..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchBar>
          <FilterContainer>
            <FilterIcon>
              <FaFilter />
            </FilterIcon>
            <FilterSelect
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="all">All Events</option>
              <option value="upcoming">Upcoming</option>
              <option value="past">Past Events</option>
            </FilterSelect>
          </FilterContainer>
        </SearchFilterContainer>
      </Header>

      {error ? (
        <ErrorContainer
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
        >
          <ErrorIcon>⚠️</ErrorIcon>
          <ErrorMessage>{error}</ErrorMessage>
          <RetryButton onClick={() => window.location.reload()}>
            Try Again
          </RetryButton>
        </ErrorContainer>
      ) : (
        <AnimatePresence>
          {filteredEvents.length === 0 ? (
            <NoEventsContainer
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <NoEventsIcon>🎭</NoEventsIcon>
              <NoEventsMessage>
                {searchTerm
                  ? "No events match your search"
                  : "No events available at the moment"}
              </NoEventsMessage>
            </NoEventsContainer>
          ) : (
            <EventsGrid>
              {filteredEvents.map((event) => (
                <EventCard
                  key={event.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  whileHover={{ y: -5 }}
                  transition={{ duration: 0.2 }}
                >
                  <EventImage src={event.image} alt={event.title} />
                  <EventContent>
                    <EventTitle>{event.title}</EventTitle>
                    <EventInfo>
                      <InfoItem>
                        <BsCalendarEvent />
                        <span>{new Date(event.date).toLocaleDateString()}</span>
                      </InfoItem>
                      <InfoItem>
                        <BsClock />
                        <span>{event.time}</span>
                      </InfoItem>
                      <InfoItem>
                        <FaTicketAlt />
                        <span>${event.price}</span>
                      </InfoItem>
                    </EventInfo>
                    <EventDescription>{event.description}</EventDescription>
                    <PurchaseButton onClick={() => window.location.href = `/streaming-events/${event.id}`}>
                      Purchase Access
                    </PurchaseButton>
                  </EventContent>
                </EventCard>
              ))}
            </EventsGrid>
          )}
        </AnimatePresence>
      )}
    </Container>
  );
};

export default StreamingEvents;

const Container = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background: linear-gradient(180deg, #030617 0%, #0a1128 100%);
  min-height: 100vh;
`;

const Header = styled.div`
  margin-bottom: 2rem;
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
`;

const Title = styled.h1`
  color: white;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
  
  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background: #4b7bec;
    margin: 1rem auto;
    border-radius: 2px;
  }
`;

const SearchFilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SearchBar = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(74, 144, 226, 0.3);
`;

const SearchIcon = styled.span`
  color: #4a90e2;
  margin-right: 0.5rem;
`;

const SearchInput = styled.input`
  flex: 1;
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  outline: none;
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(74, 144, 226, 0.3);
`;

const FilterIcon = styled.span`
  color: #4a90e2;
  margin-right: 0.5rem;
`;

const FilterSelect = styled.select`
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  
  option {
    background: #030617;
    color: white;
  }
`;

const EventsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const EventCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(74, 144, 226, 0.1);
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const EventImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const EventContent = styled.div`
  padding: 1.5rem;
`;

const EventTitle = styled.h2`
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const EventInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4a90e2;
  font-size: 0.9rem;

  svg {
    font-size: 1.1rem;
  }
`;

const EventDescription = styled.p`
  color: #888;
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const PurchaseButton = styled.button`
  width: 100%;
  padding: 0.8rem;
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #357abd;
  }

  &:disabled {
    background: #666;
    cursor: not-allowed;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
`;

const ErrorContainer = styled(motion.div)`
  padding: 2rem;
  background: rgba(255, 59, 48, 0.1);
  border-radius: 12px;
  margin: 2rem auto;
  max-width: 600px;
  text-align: center;
  border: 1px solid rgba(255, 59, 48, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const ErrorIcon = styled.div`
  font-size: 2rem;
`;

const ErrorMessage = styled.p`
  color: #ff3b30;
  font-size: 1.1rem;
`;

const RetryButton = styled.button`
  padding: 0.8rem 2rem;
  background: #ff3b30;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.9;
  }
`;

const NoEventsContainer = styled(motion.div)`
  text-align: center;
  padding: 4rem 2rem;
  color: #888;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  margin: 2rem 0;
`;

const NoEventsIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const NoEventsMessage = styled.p`
  font-size: 1.2rem;
`;
