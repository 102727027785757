import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import { motion } from 'framer-motion';
import { FaShoppingCart, FaTicketAlt, FaCalendar, FaUser } from 'react-icons/fa';
import LoadingSpinner from '../common/LoadingSpinner';

const Container = styled.div`
  padding: 2rem;
`;

const Header = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  color: white;
  margin-bottom: 1rem;
  font-size: 2rem;
  text-align: center;

  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background: #4a90e2;
    margin: 1rem auto;
    border-radius: 2px;
  }
`;

const Stats = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
`;

const StatCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StatIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4a90e2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
`;

const StatContent = styled.div``;

const StatValue = styled.div`
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
`;

const StatLabel = styled.div`
  color: #888;
  font-size: 0.9rem;
`;

const Table = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  overflow: hidden;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  padding: 1rem;
  background: rgba(74, 144, 226, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 768px) {
    grid-template-columns: 2fr 1fr 1fr;
  }
`;

const TableHeaderCell = styled.div`
  color: #4a90e2;
  font-weight: 600;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    &:nth-child(4), &:nth-child(5) {
      display: none;
    }
  }
`;

const TableRow = styled(motion.div)`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  align-items: center;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    grid-template-columns: 2fr 1fr 1fr;
  }
`;

const TableCell = styled.div`
  color: ${props => props.highlight ? '#4a90e2' : 'white'};
  font-size: 0.9rem;

  @media (max-width: 768px) {
    &:nth-child(4), &:nth-child(5) {
      display: none;
    }
  }
`;

const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ProductImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  object-fit: cover;
`;

const ProductName = styled.div`
  color: white;
  font-weight: 500;
`;

const ProductType = styled.div`
  color: #888;
  font-size: 0.8rem;
`;

const NoSalesMessage = styled.div`
  text-align: center;
  padding: 4rem 2rem;
  color: #888;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
`;

const NoSalesIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #4a90e2;
`;

const SalesHistory = () => {
  const [sales, setSales] = useState([]);
  const [stats, setStats] = useState({
    totalRevenue: 0,
    productsSold: 0,
    ticketsSold: 0,
    uniqueCustomers: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSales = async () => {
      try {
        // Fetch product orders
        const ordersQuery = query(
          collection(db, 'orders'),
          where('artistId', '==', 'current-user-id'), // Replace with actual user ID
          orderBy('createdAt', 'desc')
        );
        const ordersSnapshot = await getDocs(ordersQuery);
        const productSales = ordersSnapshot.docs.map(doc => ({
          id: doc.id,
          type: 'product',
          ...doc.data()
        }));

        // Fetch ticket sales
        const ticketsQuery = query(
          collection(db, 'tickets'),
          where('artistId', '==', 'current-user-id'), // Replace with actual user ID
          orderBy('createdAt', 'desc')
        );
        const ticketsSnapshot = await getDocs(ticketsQuery);
        const ticketSales = ticketsSnapshot.docs.map(doc => ({
          id: doc.id,
          type: 'ticket',
          ...doc.data()
        }));

        // Combine and sort sales
        const allSales = [...productSales, ...ticketSales].sort((a, b) => 
          b.createdAt.toDate() - a.createdAt.toDate()
        );

        // Calculate stats
        const uniqueCustomers = new Set([
          ...productSales.map(sale => sale.userId),
          ...ticketSales.map(sale => sale.userId)
        ]).size;

        setStats({
          totalRevenue: allSales.reduce((acc, sale) => acc + sale.price, 0),
          productsSold: productSales.length,
          ticketsSold: ticketSales.length,
          uniqueCustomers
        });

        setSales(allSales);
      } catch (error) {
        console.error('Error fetching sales:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSales();
  }, []);

  if (loading) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }

  if (sales.length === 0) {
    return (
      <Container>
        <Header>
          <Title>Sales History</Title>
        </Header>
        <NoSalesMessage>
          <NoSalesIcon>
            <FaShoppingCart />
          </NoSalesIcon>
          <p>No sales recorded yet.</p>
        </NoSalesMessage>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Title>Sales History</Title>
        <Stats>
          <StatCard whileHover={{ scale: 1.02 }}>
            <StatIcon>
              <FaShoppingCart />
            </StatIcon>
            <StatContent>
              <StatValue>${stats.totalRevenue.toFixed(2)}</StatValue>
              <StatLabel>Total Revenue</StatLabel>
            </StatContent>
          </StatCard>
          <StatCard whileHover={{ scale: 1.02 }}>
            <StatIcon>
              <FaShoppingCart />
            </StatIcon>
            <StatContent>
              <StatValue>{stats.productsSold}</StatValue>
              <StatLabel>Products Sold</StatLabel>
            </StatContent>
          </StatCard>
          <StatCard whileHover={{ scale: 1.02 }}>
            <StatIcon>
              <FaTicketAlt />
            </StatIcon>
            <StatContent>
              <StatValue>{stats.ticketsSold}</StatValue>
              <StatLabel>Tickets Sold</StatLabel>
            </StatContent>
          </StatCard>
          <StatCard whileHover={{ scale: 1.02 }}>
            <StatIcon>
              <FaUser />
            </StatIcon>
            <StatContent>
              <StatValue>{stats.uniqueCustomers}</StatValue>
              <StatLabel>Unique Customers</StatLabel>
            </StatContent>
          </StatCard>
        </Stats>
      </Header>

      <Table>
        <TableHeader>
          <TableHeaderCell>Item</TableHeaderCell>
          <TableHeaderCell>Price</TableHeaderCell>
          <TableHeaderCell>Date</TableHeaderCell>
          <TableHeaderCell>Customer</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
        </TableHeader>
        {sales.map(sale => (
          <TableRow
            key={sale.id}
            whileHover={{ backgroundColor: 'rgba(255, 255, 255, 0.02)' }}
          >
            <TableCell>
              <ProductInfo>
                <ProductImage src={sale.imageUrl} alt={sale.title} />
                <div>
                  <ProductName>{sale.title}</ProductName>
                  <ProductType>{sale.type === 'product' ? 'Product' : 'Event Ticket'}</ProductType>
                </div>
              </ProductInfo>
            </TableCell>
            <TableCell highlight>${sale.price.toFixed(2)}</TableCell>
            <TableCell>
              {sale.createdAt.toDate().toLocaleDateString()}
            </TableCell>
            <TableCell>{sale.userId}</TableCell>
            <TableCell highlight>{sale.status}</TableCell>
          </TableRow>
        ))}
      </Table>
    </Container>
  );
};

export default SalesHistory;
