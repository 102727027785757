import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import styled from 'styled-components';
import { db } from '../../firebase';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import Nav3 from '../Navs/SideView/Nav3';

const DashboardContainer = styled.div`
  min-height: 100vh;
  background-color: #030617;
  color: white;
  padding: 2rem;
`;

const TabContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const Tab = styled.button`
  background: ${props => props.active ? '#4a90e2' : 'transparent'};
  color: white;
  border: 1px solid #4a90e2;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #4a90e2;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const Card = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 1.5rem;
`;

const Button = styled.button`
  background: ${props => props.danger ? '#ff4444' : '#4a90e2'};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5rem;

  &:hover {
    opacity: 0.9;
  }
`;

const AdminDashboard = () => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState('artists');
  const [artists, setArtists] = useState([]);
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (activeTab === 'artists') {
      fetchArtists();
    } else if (activeTab === 'users') {
      fetchUsers();
    } else {
      fetchProducts();
    }
  }, [activeTab]);

  const fetchArtists = async () => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('role', '==', 'artist'));
      const querySnapshot = await getDocs(q);
      
      const artistsData = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        $id: doc.id
      }));
      setArtists(artistsData);
    } catch (error) {
      console.error('Error fetching artists:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const usersRef = collection(db, 'users');
      const querySnapshot = await getDocs(usersRef);
      
      const usersData = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        $id: doc.id
      }));
      setUsers(usersData);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const productsRef = collection(db, 'products');
      const querySnapshot = await getDocs(productsRef);
      
      const productsData = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        $id: doc.id
      }));
      setProducts(productsData);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleApproveArtist = async (artistId) => {
    try {
      const artistRef = doc(db, 'users', artistId);
      await updateDoc(artistRef, {
        status: 'approved'
      });
      fetchArtists();
    } catch (error) {
      console.error('Error approving artist:', error);
    }
  };

  const handleRejectArtist = async (artistId) => {
    try {
      const artistRef = doc(db, 'users', artistId);
      await updateDoc(artistRef, {
        status: 'rejected'
      });
      fetchArtists();
    } catch (error) {
      console.error('Error rejecting artist:', error);
    }
  };

  const handleApproveProduct = async (productId) => {
    try {
      const productRef = doc(db, 'products', productId);
      await updateDoc(productRef, {
        status: 'active'
      });
      fetchProducts();
    } catch (error) {
      console.error('Error approving product:', error);
    }
  };

  const handleRejectProduct = async (productId) => {
    try {
      const productRef = doc(db, 'products', productId);
      await updateDoc(productRef, {
        status: 'rejected'
      });
      fetchProducts();
    } catch (error) {
      console.error('Error rejecting product:', error);
    }
  };

  return (
    <DashboardContainer>
      <Nav3 />
      <h1>Admin Dashboard</h1>
      <p>Welcome, {user?.name}</p>

      <TabContainer>
        <Tab
          active={activeTab === 'artists'}
          onClick={() => setActiveTab('artists')}
        >
          Artists
        </Tab>
        <Tab
          active={activeTab === 'users'}
          onClick={() => setActiveTab('users')}
        >
          Users
        </Tab>
        <Tab
          active={activeTab === 'products'}
          onClick={() => setActiveTab('products')}
        >
          Products
        </Tab>
      </TabContainer>

      {activeTab === 'artists' ? (
        <Grid>
          {artists.map(artist => (
            <Card key={artist.$id}>
              <h3>{artist.name}</h3>
              <p>Email: {artist.email}</p>
              <p>Status: {artist.status}</p>
              {artist.status === 'pending' && (
                <>
                  <Button onClick={() => handleApproveArtist(artist.$id)}>
                    Approve
                  </Button>
                  <Button danger onClick={() => handleRejectArtist(artist.$id)}>
                    Reject
                  </Button>
                </>
              )}
            </Card>
          ))}
        </Grid>
      ) : activeTab === 'users' ? (
        <Grid>
          {users.map(user => (
            <Card key={user.$id}>
              <h3>{user.name}</h3>
              <p>Email: {user.email}</p>
              <p>Role: {user.role}</p>
            </Card>
          ))}
        </Grid>
      ) : (
        <Grid>
          {products.map(product => (
            <Card key={product.$id}>
              <h3>{product.name}</h3>
              <p>Artist: {product.artistName}</p>
              <p>Price: UGX {product.price.toLocaleString()}</p>
              <p>Status: {product.status}</p>
              {product.status === 'pending' && (
                <>
                  <Button onClick={() => handleApproveProduct(product.$id)}>
                    Approve
                  </Button>
                  <Button danger onClick={() => handleRejectProduct(product.$id)}>
                    Reject
                  </Button>
                </>
              )}
            </Card>
          ))}
        </Grid>
      )}
    </DashboardContainer>
  );
};

export default AdminDashboard;
