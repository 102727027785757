import { useEffect, useRef } from 'react';

const useAdsense = () => {
  const adRef = useRef(null);

  useEffect(() => {
    try {
      if (!adRef.current) return;

      const adElement = adRef.current.querySelector('.adsbygoogle');
      
      // Check if the ad is already initialized
      if (adElement && adElement.getAttribute('data-ad-status')) {
        return;
      }

      // Check if we're in development mode
      if (process.env.NODE_ENV === 'development') {
        console.log('AdSense disabled in development mode');
        return;
      }

      // Initialize ads only if adsbygoogle is available and not already initialized
      if (window.adsbygoogle) {
        const push = () => {
          try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          } catch (error) {
            console.error('Failed to push ad:', error);
          }
        };

        // If the script is still loading, wait for it
        if (document.readyState !== 'complete') {
          window.addEventListener('load', push);
          return () => window.removeEventListener('load', push);
        }

        push();
      }
    } catch (error) {
      console.error('Error initializing ad:', error);
    }
  }, []);

  return adRef;
}

export default useAdsense;
