import React, { useState } from 'react';
import styled from 'styled-components';
import { db, collections } from '../../firebase.marketplace';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';

const FormContainer = styled.div`
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  color: white;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  color: white;

  &:focus {
    outline: none;
    border-color: #4a90e2;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #357abd;
  }

  &:disabled {
    background: #666;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #ff6b6b;
  margin-top: 1rem;
`;

const BillingForm = ({ product, artist, onSuccess }) => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    country: '',
    paymentMethod: 'card', // or other payment methods you support
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!currentUser) {
      setError('Please login to make a purchase');
      setLoading(false);
      return;
    }

    try {
      // Create order in database
      const ordersRef = collection(db, collections.ORDERS);
      const orderData = {
        productId: product.$id,
        buyerId: currentUser.uid,
        sellerId: product.sellerId,
        amount: product.price,
        status: 'pending',
        createdAt: serverTimestamp(),
        ...formData
      };

      const orderDoc = await addDoc(ordersRef, orderData);
      console.log('Order created successfully:', orderDoc.id);

      // Handle payment processing here
      // You would typically integrate with a payment gateway like Stripe

      if (onSuccess) {
        onSuccess(orderDoc.id);
      }
    } catch (error) {
      console.error('Error creating order:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContainer>
      <h2>Billing Information</h2>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="fullName"
          placeholder="Full Name"
          value={formData.fullName}
          onChange={handleChange}
          required
        />
        <Input
          type="tel"
          name="phone"
          placeholder="Phone Number"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        <Input
          type="text"
          name="address"
          placeholder="Address"
          value={formData.address}
          onChange={handleChange}
          required
        />
        <Input
          type="text"
          name="city"
          placeholder="City"
          value={formData.city}
          onChange={handleChange}
          required
        />
        <Input
          type="text"
          name="country"
          placeholder="Country"
          value={formData.country}
          onChange={handleChange}
          required
        />
        {/* Add payment method selection here if needed */}
        
        <Button type="submit" disabled={loading}>
          {loading ? 'Processing...' : `Pay ${product.price} USD`}
        </Button>
        
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Form>
    </FormContainer>
  );
};

export default BillingForm;
