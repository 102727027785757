import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import { db, collections, ROLES } from '../../firebase';
import { 
    collection, 
    query, 
    where, 
    getDocs, 
    doc, 
    updateDoc, 
    getDoc,
    arrayUnion,
    serverTimestamp 
} from 'firebase/firestore';

const Container = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h1`
  color: white;
  margin-bottom: 2rem;
`;

const RequestCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  backdrop-filter: blur(10px);
`;

const RequestInfo = styled.div`
  margin-bottom: 1rem;
  color: white;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;

  &.approve {
    background: #4CAF50;
    color: white;
    &:hover { background: #45a049; }
  }

  &.reject {
    background: #f44336;
    color: white;
    &:hover { background: #da190b; }
  }

  &:disabled {
    background: #666;
    cursor: not-allowed;
  }
`;

const StatusBadge = styled.span`
  padding: 0.25rem 0.5rem;
  border-radius: 15px;
  font-size: 0.875rem;
  margin-left: 1rem;
  
  &.pending {
    background: #ffd700;
    color: black;
  }
  
  &.approved {
    background: #4CAF50;
    color: white;
  }
  
  &.rejected {
    background: #f44336;
    color: white;
  }
`;

const ArtistRequestsDashboard = () => {
  const { user, userRoles } = useAuth();
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch artist requests
  const fetchRequests = async () => {
    try {
      const requestsRef = collection(db, collections.ARTIST_REQUESTS);
      const q = query(requestsRef);
      const querySnapshot = await getDocs(q);
      
      const requestsData = await Promise.all(querySnapshot.docs.map(async (doc) => {
        const data = doc.data();
        // Get user details
        const userDoc = await getDoc(doc(db, collections.USERS, data.userId));
        const userData = userDoc.exists() ? userDoc.data() : {};
        
        return {
          id: doc.id,
          ...data,
          user: userData
        };
      }));
      
      setRequests(requestsData);
    } catch (err) {
      console.error('Error fetching requests:', err);
      setError('Failed to load artist requests');
    } finally {
      setLoading(false);
    }
  };

  // Handle request approval
  const handleApprove = async (request) => {
    try {
      // Update request status
      const requestRef = doc(db, collections.ARTIST_REQUESTS, request.id);
      await updateDoc(requestRef, {
        status: 'approved',
        approvedBy: user.uid,
        approvedAt: serverTimestamp()
      });

      // Update user roles
      const userRef = doc(db, collections.USERS, request.userId);
      await updateDoc(userRef, {
        roles: arrayUnion(ROLES.ARTIST),
        updatedAt: serverTimestamp()
      });

      // Refresh requests list
      fetchRequests();
    } catch (err) {
      console.error('Error approving request:', err);
      setError('Failed to approve request');
    }
  };

  // Handle request rejection
  const handleReject = async (request) => {
    try {
      const requestRef = doc(db, collections.ARTIST_REQUESTS, request.id);
      await updateDoc(requestRef, {
        status: 'rejected',
        rejectedBy: user.uid,
        rejectedAt: serverTimestamp()
      });

      // Refresh requests list
      fetchRequests();
    } catch (err) {
      console.error('Error rejecting request:', err);
      setError('Failed to reject request');
    }
  };

  useEffect(() => {
    if (!userRoles.includes(ROLES.ADMIN)) {
      setError('Access denied. Admin rights required.');
      return;
    }
    fetchRequests();
  }, [userRoles]);

  if (error) {
    return (
      <Container>
        <Title>Artist Requests</Title>
        <div style={{ color: 'red' }}>{error}</div>
      </Container>
    );
  }

  if (loading) {
    return (
      <Container>
        <Title>Artist Requests</Title>
        <div style={{ color: 'white' }}>Loading...</div>
      </Container>
    );
  }

  return (
    <Container>
      <Title>Artist Requests</Title>
      {requests.length === 0 ? (
        <div style={{ color: 'white' }}>No artist requests found.</div>
      ) : (
        requests.map((request) => (
          <RequestCard key={request.id}>
            <RequestInfo>
              <h3>
                {request.displayName || request.name}
                <StatusBadge className={request.status}>{request.status}</StatusBadge>
              </h3>
              <p>Email: {request.email}</p>
              <p>Phone: {request.phone}</p>
              <p>Bio: {request.bio}</p>
              <p>Submitted: {request.submittedAt?.toDate().toLocaleString()}</p>
            </RequestInfo>
            {request.status === 'pending' && (
              <ButtonGroup>
                <Button 
                  className="approve"
                  onClick={() => handleApprove(request)}
                >
                  Approve
                </Button>
                <Button 
                  className="reject"
                  onClick={() => handleReject(request)}
                >
                  Reject
                </Button>
              </ButtonGroup>
            )}
          </RequestCard>
        ))
      )}
    </Container>
  );
};

export default ArtistRequestsDashboard;
