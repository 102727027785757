import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { db, collections } from '../../firebase.marketplace';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import ProductCard from './ProductCard';

const ProductsContainer = styled.div`
  padding: 2rem;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const FilterButton = styled.button`
  padding: 0.5rem 1rem;
  background: ${props => props.active ? '#4a90e2' : 'transparent'};
  border: 1px solid #4a90e2;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #4a90e2;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
`;

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [artists, setArtists] = useState({});
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all'); // 'all', 'current', or artistId

  useEffect(() => {
    loadProducts();
    loadArtists();
  }, [filter]);

  const loadArtists = async () => {
    try {
      const artistsRef = collection(db, collections.USERS);
      const q = query(artistsRef, where('roles.artist', '==', true));
      const querySnapshot = await getDocs(q);
      
      const artistsMap = {};
      querySnapshot.forEach(doc => {
        artistsMap[doc.id] = { ...doc.data(), $id: doc.id };
      });
      setArtists(artistsMap);
    } catch (error) {
      console.error('Error loading artists:', error);
    }
  };

  const loadProducts = async () => {
    try {
      const productsRef = collection(db, collections.PRODUCTS);
      let q = query(productsRef, where('status', '==', 'active'));
      
      if (filter === 'current') {
        const oneWeekAgo = Timestamp.fromDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
        q = query(
          productsRef, 
          where('status', '==', 'active'),
          where('createdAt', '>=', oneWeekAgo)
        );
      } else if (filter !== 'all') {
        // filter is an artistId
        q = query(
          productsRef,
          where('status', '==', 'active'),
          where('sellerId', '==', filter)
        );
      }

      const querySnapshot = await getDocs(q);
      const productsData = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        $id: doc.id
      }));

      setProducts(productsData);
    } catch (error) {
      console.error('Error loading products:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ProductsContainer>
      <FilterContainer>
        <FilterButton 
          active={filter === 'all'} 
          onClick={() => setFilter('all')}
        >
          All Products
        </FilterButton>
        <FilterButton 
          active={filter === 'current'} 
          onClick={() => setFilter('current')}
        >
          Latest Releases
        </FilterButton>
        {Object.values(artists).map(artist => (
          <FilterButton
            key={artist.$id}
            active={filter === artist.$id}
            onClick={() => setFilter(artist.$id)}
          >
            {artist.name}
          </FilterButton>
        ))}
      </FilterContainer>

      <Grid>
        {loading ? (
          <div>Loading...</div>
        ) : (
          products.map(product => (
            <ProductCard
              key={product.$id}
              product={product}
              artist={artists[product.sellerId]}
            />
          ))
        )}
      </Grid>
    </ProductsContainer>
  );
};

export default ProductList;
