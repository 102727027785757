import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";
import RecentSlides from "../Silder/RecentSilder";
import Button from '@mui/material/Button';
import Modal from "./Modal";
import Nav3 from "../../../Navs/SideView/Nav3";
import { PlayCircle, PauseCircle, VolumeUp, VolumeOff } from "@mui/icons-material";
import GoogleAd3 from "../../../GoogleAd3";
import CompliexAd from "../../../Compliex";
import { Container, Paper, Typography, Box, Grid, IconButton, Slider } from '@mui/material';
import ReactPlayer from 'react-player';
import ViewCounter from '../../../ViewCounter';
import { db } from "../../../../firebase";
import { doc, getDoc } from 'firebase/firestore';

const FullWrapper = styled.div`
  min-height: 100vh;
  background: linear-gradient(180deg, #030617 0%, #0a1128 100%);
`;

const ContentWrapper = styled(Paper)`
  background: rgba(255, 255, 255, 0.05) !important;
  backdrop-filter: blur(10px);
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  margin: 1rem auto;
  max-width: 600px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 12px;
`;

const Title = styled(Typography)`
  color: #ffffff;
  font-size: 0.85rem;
  font-weight: 500;
  text-align: center;
  margin: 0.5rem 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  max-width: 100%;
  padding: 0 0.5rem;
`;

const DownloadSection = styled(Box)`
  text-align: center;
  margin: 1.5rem 0;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
`;

const NoticeText = styled(Typography)`
  color: #ffd700;
  font-size: 0.75rem;
  margin: 0.5rem 0;
  font-style: italic;
`;

const DonateSection = styled(Box)`
  text-align: center;
  margin: 1.5rem 0;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
`;

const LoadingContainer = styled(Box)`
  padding: 2rem;
  text-align: center;
  background: rgba(19, 28, 35, 0.8);
  border-radius: 12px;
  margin: 1rem;
`;

const PlayerContainer = styled(Box)`
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 0.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
`;

const ProgressBar = styled(Slider)`
  color: #2196F3;
  height: 4px;
  padding: 0;
  margin: 0.5rem 0;
  
  .MuiSlider-thumb {
    width: 8px;
    height: 8px;
    display: none;
  }

  &:hover .MuiSlider-thumb {
    display: block;
  }
`;

const VolumeSlider = styled(Slider)`
  color: #2196F3;
  width: 80px;
  height: 4px;
  padding: 0;
  margin: 0 0.5rem;
`;

const Converter = () => {
  const { id } = useParams();

  const [song, setSong] = useState(null);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [muted, setMuted] = useState(false);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const playerRef = React.useRef(null);

  useEffect(() => {
    const fetchSong = async () => {
      try {
        const songRef = doc(db, "songs", id);
        const docSnap = await getDoc(songRef);
        
        if (docSnap.exists()) {
          setSong({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.log("No such document in firebase");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
    };

    if (id) {
      fetchSong();
    }
  }, [id]);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    setMuted(newValue === 0);
  };

  const handleMuteToggle = () => {
    setMuted(!muted);
  };

  const handleProgress = (state) => {
    setPlayed(state.played);
  };

  const handleSeekChange = (event, newValue) => {
    setPlayed(newValue);
    playerRef.current.seekTo(newValue);
  };

  const formatTime = (seconds) => {
    const date = new Date(seconds * 1000);
    const mm = date.getUTCMinutes();
    const ss = String(date.getUTCSeconds()).padStart(2, '0');
    return `${mm}:${ss}`;
  };

  return (
    <FullWrapper>
      <Nav3 />
      {song ? (
        <Container maxWidth="md">
          <ContentWrapper elevation={3}>
            <Helmet>
              <title>{song.title} | MP3 Download | Audio Download</title>
              <meta
                property="og:title"
                content={`${song.title} | MP3 Download | Audio Download`}
                key="og-title"
              />
              <meta
                property="og:description"
                content={`${song.title} | MP3 Download | Audio Download`}
                key="og-desc"
              />
              <meta
                property="og:url"
                content={`https://konzovibez.biz/${song.id}`}
                key="og-url"
              />
              <meta property="og:image" content={song.image} key="og-image" />
              <meta
                property="og:site_name"
                content="Konzo Vibez"
                key="og-site"
              />
              <meta
                name="twitter:title"
                content={`${song.title} | MP3 Download | Audio Download`}
                key="tw-title"
              />
              <meta
                name="twitter:description"
                content={`${song.title} | MP3 Download | Audio Download`}
                key="tw-desc"
              />
              <meta
                name="twitter:image"
                content={song.image}
                key="tw-image"
              />
              <meta
                name="twitter:card"
                content="summary_large_image"
                key="tw-card"
              />
              <link
                rel="canonical"
                href={`https://konzovibez.biz/${song.id}`}
              />
              <link rel="icon" href={song.image} />
            </Helmet>

            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <ImageContainer>
                  <StyledImage src={song.image} alt={song.title} />
                </ImageContainer>
              </Grid>
              
              <Grid item xs={12}>
                <Title variant="body2" component="h1" title={song.title}>
                  {song.title}
                </Title>
                
                <PlayerContainer>
                  <ReactPlayer
                    ref={playerRef}
                    url={`https://www.youtube.com/watch?v=${song.url}`}
                    width="0"
                    height="0"
                    playing={playing}
                    volume={volume}
                    muted={muted}
                    onProgress={handleProgress}
                    onDuration={setDuration}
                    config={{
                      youtube: {
                        playerVars: { showinfo: 0, controls: 0 }
                      }
                    }}
                  />
                  
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <IconButton 
                      onClick={handlePlayPause}
                      size="small"
                      sx={{ color: 'white' }}
                    >
                      {playing ? <PauseCircle /> : <PlayCircle />}
                    </IconButton>
                    
                    <Box sx={{ flex: 1, mx: 1 }}>
                      <ProgressBar
                        value={played}
                        onChange={handleSeekChange}
                        min={0}
                        max={1}
                        step={0.001}
                      />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
                        <Typography variant="caption" color="white">
                          {formatTime(duration * played)}
                        </Typography>
                        <Typography variant="caption" color="white">
                          {formatTime(duration)}
                        </Typography>
                      </Box>
                    </Box>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        onClick={handleMuteToggle}
                        size="small"
                        sx={{ color: 'white' }}
                      >
                        {muted ? <VolumeOff /> : <VolumeUp />}
                      </IconButton>
                      <VolumeSlider
                        value={volume}
                        onChange={handleVolumeChange}
                        min={0}
                        max={1}
                        step={0.01}
                        sx={{ mr: 1 }}
                      />
                    </Box>
                  </Box>
                </PlayerContainer>

                <DownloadSection>
                  <Typography variant="body2" color="white" gutterBottom>
                    Download MP3 version:
                  </Typography>
                  <Box sx={{ my: 1 }}>
                    <iframe 
                      title="Music Player"
                      className="button-api-frame"
                      src={`https://mp3api.ytjar.info/?id=${song.url}&c=ffff&b=0000ff&t`}
                      width="140px"
                      height="36px"
                      style={{ border: 'none', borderRadius: '6px' }}
                    />
                  </Box>
                  <NoticeText>
                    NOTE: Turn off VPN to see download button
                  </NoticeText>
                </DownloadSection>
              </Grid>
            </Grid>
          </ContentWrapper>
          <ViewCounter path={`/music/${id}`} />

          <DonateSection>
            <Typography variant="body2" color="white" gutterBottom>
              Beyond Barriers: Celebrating the Diverse Experiences of People with Disabilities
            </Typography>
            <Typography variant="caption" color="white" paragraph>
              Please donate to help us acquire radio equipment
            </Typography>
            <Button 
              variant="contained" 
              href='https://www.paypal.com/donate/?hosted_button_id=UBRGNLABD5ZLE' 
              color="success"
              size="small"
              sx={{ 
                borderRadius: '20px',
                padding: '6px 16px',
                fontSize: '0.75rem',
                background: 'linear-gradient(45deg, #4CAF50 30%, #81C784 90%)',
                '&:hover': {
                  background: 'linear-gradient(45deg, #81C784 30%, #4CAF50 90%)',
                }
              }}
            >
              Donate
            </Button>
          </DonateSection>

          <Box sx={{ my: 3 }}>
            <Typography variant="body2" color="white" align="center" gutterBottom>
              Check out the current Music
            </Typography>
            <RecentSlides />
          </Box>
          
          <GoogleAd3 />
          <CompliexAd />
        </Container>
      ) : (
        <LoadingContainer>
          <SkeletonTheme baseColor="#1E293B" highlightColor="#334155">
            <Box sx={{ maxWidth: 400, margin: '0 auto' }}>
              <Typography variant="body2" color="white" gutterBottom>
                Loading...
              </Typography>
              <Skeleton height={200} style={{ borderRadius: '12px', marginBottom: '1rem' }} />
              <Skeleton height={32} style={{ borderRadius: '6px', marginBottom: '0.5rem' }} />
              <Skeleton height={32} style={{ borderRadius: '6px', marginBottom: '0.5rem' }} />
              <Skeleton height={32} style={{ borderRadius: '6px' }} />
            </Box>
          </SkeletonTheme>
        </LoadingContainer>
      )}

      <Modal
        isOpen={showDownloadModal}
        closeModal={() => setShowDownloadModal(false)}
      />
    </FullWrapper>
  );
};

export default Converter;
