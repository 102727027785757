import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaLock, FaCreditCard, FaMobile } from 'react-icons/fa';
import { useCart } from '../../context/CartContext';
import { doc, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';

const Container = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  color: white;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;

  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background: #4a90e2;
    margin: 1rem auto;
    border-radius: 2px;
  }
`;

const CheckoutGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Form = styled.form`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 2rem;
`;

const FormSection = styled.div`
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.h2`
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: #4a90e2;
  }
`;

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const InputGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  color: #888;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: #4a90e2;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;

const PaymentMethods = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
`;

const PaymentMethod = styled.button`
  background: ${props => props.active ? 'rgba(74, 144, 226, 0.2)' : 'rgba(255, 255, 255, 0.1)'};
  border: 1px solid ${props => props.active ? '#4a90e2' : 'rgba(255, 255, 255, 0.2)'};
  border-radius: 8px;
  padding: 1rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(74, 144, 226, 0.1);
  }

  svg {
    color: #4a90e2;
  }
`;

const OrderSummary = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 1.5rem;
  height: fit-content;
`;

const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${props => props.total ? 'white' : '#888'};
  font-size: ${props => props.total ? '1.2rem' : '1rem'};
  font-weight: ${props => props.total ? '600' : '400'};
  margin-bottom: 1rem;
  padding-bottom: ${props => props.total ? '1rem' : '0'};
  border-bottom: ${props => props.total ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'};
`;

const PlaceOrderButton = styled(motion.button)`
  width: 100%;
  padding: 1rem;
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:disabled {
    background: #666;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background: #357abd;
  }
`;

const ErrorMessage = styled.div`
  color: #ff4d4d;
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;

const Checkout = () => {
  const navigate = useNavigate();
  const { cart, getCartTotal, clearCart } = useCart();
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    country: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
    mobileNumber: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      // Create order in Firestore
      const orderRef = await addDoc(collection(db, 'orders'), {
        userId: 'current-user-id', // Replace with actual user ID
        items: cart.items,
        total: getCartTotal(),
        paymentMethod,
        shippingAddress: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          address: formData.address,
          city: formData.city,
          country: formData.country,
          phone: formData.phone
        },
        status: 'pending',
        createdAt: serverTimestamp()
      });

      // Process payment (integrate with payment gateway here)
      if (paymentMethod === 'card') {
        // Implement card payment processing
        // This is where you'd integrate with Stripe or another payment processor
      } else if (paymentMethod === 'mobile') {
        // Implement mobile money payment processing
      }

      // Clear cart and redirect to success page
      clearCart();
      navigate('/marketplace/order-success', { 
        state: { orderId: orderRef.id }
      });
    } catch (error) {
      console.error('Error processing order:', error);
      setError('An error occurred while processing your order. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Header>
        <Title>Checkout</Title>
      </Header>
      <CheckoutGrid>
        <Form onSubmit={handleSubmit}>
          <FormSection>
            <SectionTitle>
              <FaLock /> Contact Information
            </SectionTitle>
            <InputGrid>
              <InputGroup>
                <Label>First Name</Label>
                <Input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
              <InputGroup>
                <Label>Last Name</Label>
                <Input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </InputGrid>
            <InputGrid>
              <InputGroup>
                <Label>Email</Label>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
              <InputGroup>
                <Label>Phone</Label>
                <Input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </InputGrid>
          </FormSection>

          <FormSection>
            <SectionTitle>
              <FaLock /> Shipping Address
            </SectionTitle>
            <InputGroup>
              <Label>Address</Label>
              <Input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
              />
            </InputGroup>
            <InputGrid>
              <InputGroup>
                <Label>City</Label>
                <Input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
              <InputGroup>
                <Label>Country</Label>
                <Input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </InputGrid>
          </FormSection>

          <FormSection>
            <SectionTitle>
              <FaLock /> Payment Method
            </SectionTitle>
            <PaymentMethods>
              <PaymentMethod
                type="button"
                active={paymentMethod === 'card'}
                onClick={() => setPaymentMethod('card')}
              >
                <FaCreditCard /> Credit Card
              </PaymentMethod>
              <PaymentMethod
                type="button"
                active={paymentMethod === 'mobile'}
                onClick={() => setPaymentMethod('mobile')}
              >
                <FaMobile /> Mobile Money
              </PaymentMethod>
            </PaymentMethods>

            {paymentMethod === 'card' && (
              <>
                <InputGroup>
                  <Label>Card Number</Label>
                  <Input
                    type="text"
                    name="cardNumber"
                    value={formData.cardNumber}
                    onChange={handleInputChange}
                    placeholder="1234 5678 9012 3456"
                    required
                  />
                </InputGroup>
                <InputGrid>
                  <InputGroup>
                    <Label>Expiry Date</Label>
                    <Input
                      type="text"
                      name="cardExpiry"
                      value={formData.cardExpiry}
                      onChange={handleInputChange}
                      placeholder="MM/YY"
                      required
                    />
                  </InputGroup>
                  <InputGroup>
                    <Label>CVC</Label>
                    <Input
                      type="text"
                      name="cardCvc"
                      value={formData.cardCvc}
                      onChange={handleInputChange}
                      placeholder="123"
                      required
                    />
                  </InputGroup>
                </InputGrid>
              </>
            )}

            {paymentMethod === 'mobile' && (
              <InputGroup>
                <Label>Mobile Money Number</Label>
                <Input
                  type="tel"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleInputChange}
                  placeholder="Enter your mobile money number"
                  required
                />
              </InputGroup>
            )}
          </FormSection>

          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Form>

        <OrderSummary>
          <SectionTitle>Order Summary</SectionTitle>
          <SummaryItem>
            <span>Subtotal</span>
            <span>${getCartTotal().toFixed(2)}</span>
          </SummaryItem>
          <SummaryItem>
            <span>Shipping</span>
            <span>Free</span>
          </SummaryItem>
          <SummaryItem total>
            <span>Total</span>
            <span>${getCartTotal().toFixed(2)}</span>
          </SummaryItem>
          <PlaceOrderButton
            type="submit"
            disabled={loading}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <FaLock />
            {loading ? 'Processing...' : 'Place Order'}
          </PlaceOrderButton>
        </OrderSummary>
      </CheckoutGrid>
    </Container>
  );
};

export default Checkout;
