import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import counterReducer from '../features/counter/counterSlice';
import artistReducer from '../features/artist/artistSlice';
import songReducer from '../features/song/songSlice';
import nonstopReducer from '../features/nonstop/nonstopSlice';
import gospelReducer from '../features/gospel/gospelSlice';
import oldieReducer from '../features/oldies/oldieSlice';
import musikReducer from '../features/musik/musikSlice';
import talentReducer from '../features/talent/talentSlice';
import recentReducer from '../features/recent/recentSlice';
import chartReducer from '../features/chart/chartSlice';
import marketplaceReducer from '../features/marketplace/marketplaceSlice';

export const store = configureStore({
  reducer: {
    artist: artistReducer,
    song: songReducer,
    nonstop: nonstopReducer,
    gospel: gospelReducer,
    oldie: oldieReducer,
    musik: musikReducer,
    talent: talentReducer,
    recent: recentReducer,
    chart: chartReducer,
    marketplace: marketplaceReducer,
  },
});
