import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaCheckCircle, FaBox, FaEnvelope, FaPrint } from 'react-icons/fa';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const Container = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
`;

const SuccessCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
`;

const Icon = styled.div`
  color: #4CAF50;
  font-size: 4rem;
  margin-bottom: 1rem;
`;

const Title = styled.h1`
  color: white;
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  color: #888;
  font-size: 1.1rem;
  margin-bottom: 2rem;
`;

const OrderDetails = styled.div`
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 1.5rem;
  margin: 2rem 0;
  text-align: left;
`;

const OrderId = styled.div`
  color: #4a90e2;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const DetailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const DetailItem = styled.div`
  color: #888;
`;

const DetailLabel = styled.div`
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
`;

const DetailValue = styled.div`
  color: white;
  font-size: 1rem;
`;

const ItemsList = styled.div`
  margin-top: 1.5rem;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:last-child {
    border-bottom: none;
  }
`;

const ItemImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
`;

const ItemInfo = styled.div`
  flex: 1;
`;

const ItemName = styled.div`
  color: white;
  margin-bottom: 0.3rem;
`;

const ItemPrice = styled.div`
  color: #4a90e2;
  font-weight: 600;
`;

const ActionButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const ActionButton = styled(motion.button)`
  background: rgba(74, 144, 226, 0.1);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 1rem;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  svg {
    color: #4a90e2;
  }

  &:hover {
    background: rgba(74, 144, 226, 0.2);
  }
`;

const ContinueButton = styled(motion.button)`
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 1rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 2rem;
  width: 100%;

  &:hover {
    background: #357abd;
  }
`;

const OrderSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const orderId = location.state?.orderId;

  useEffect(() => {
    const fetchOrder = async () => {
      if (!orderId) {
        navigate('/marketplace');
        return;
      }

      try {
        const orderDoc = await getDoc(doc(db, 'orders', orderId));
        if (orderDoc.exists()) {
          setOrder({
            id: orderDoc.id,
            ...orderDoc.data()
          });
        }
      } catch (error) {
        console.error('Error fetching order:', error);
      }
    };

    fetchOrder();
  }, [orderId, navigate]);

  const handlePrint = () => {
    window.print();
  };

  const handleTrackOrder = () => {
    navigate(`/marketplace/orders/${orderId}`);
  };

  const handleContinueShopping = () => {
    navigate('/marketplace');
  };

  if (!order) {
    return null;
  }

  return (
    <Container>
      <SuccessCard
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Icon>
          <FaCheckCircle />
        </Icon>
        <Title>Order Successful!</Title>
        <Message>
          Thank you for your purchase. Your order has been received and is being processed.
        </Message>

        <OrderDetails>
          <OrderId>Order #{orderId}</OrderId>
          <DetailGrid>
            <DetailItem>
              <DetailLabel>Order Date</DetailLabel>
              <DetailValue>
                {new Date(order.createdAt.toDate()).toLocaleDateString()}
              </DetailValue>
            </DetailItem>
            <DetailItem>
              <DetailLabel>Total Amount</DetailLabel>
              <DetailValue>${order.total.toFixed(2)}</DetailValue>
            </DetailItem>
            <DetailItem>
              <DetailLabel>Shipping Address</DetailLabel>
              <DetailValue>
                {`${order.shippingAddress.address}, ${order.shippingAddress.city}, ${order.shippingAddress.country}`}
              </DetailValue>
            </DetailItem>
            <DetailItem>
              <DetailLabel>Payment Method</DetailLabel>
              <DetailValue>
                {order.paymentMethod === 'card' ? 'Credit Card' : 'Mobile Money'}
              </DetailValue>
            </DetailItem>
          </DetailGrid>

          <ItemsList>
            {order.items.map((item, index) => (
              <Item key={`${item.id}-${index}`}>
                <ItemImage src={item.imageUrl} alt={item.title} />
                <ItemInfo>
                  <ItemName>{item.title}</ItemName>
                  <ItemPrice>${(item.price * item.quantity).toFixed(2)}</ItemPrice>
                </ItemInfo>
              </Item>
            ))}
          </ItemsList>
        </OrderDetails>

        <ActionButtons>
          <ActionButton onClick={handleTrackOrder}>
            <FaBox /> Track Order
          </ActionButton>
          <ActionButton onClick={() => {}}>
            <FaEnvelope /> Email Receipt
          </ActionButton>
          <ActionButton onClick={handlePrint}>
            <FaPrint /> Print Order
          </ActionButton>
        </ActionButtons>

        <ContinueButton
          onClick={handleContinueShopping}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          Continue Shopping
        </ContinueButton>
      </SuccessCard>
    </Container>
  );
};

export default OrderSuccess;
