import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const RegistrationForm = styled.form`
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
`;

const FormTitle = styled.h2`
  color: white;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  color: white;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  color: white;
  min-height: 100px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #357abd;
  }

  &:disabled {
    background: #666;
    cursor: not-allowed;
  }
`;

const ArtistRegistration = () => {
  const { user, requestArtistRole } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    bio: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!user) {
      setError('Please login first');
      setLoading(false);
      return;
    }

    try {
      await requestArtistRole({
        ...formData,
        displayName: formData.name,
      });

      setSuccess(true);
      setFormData({ name: '', email: '', phone: '', bio: '' });
    } catch (error) {
      console.error('Error registering artist:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  if (success) {
    return (
      <RegistrationForm>
        <FormTitle>Registration Submitted!</FormTitle>
        <p style={{ color: 'white', textAlign: 'center' }}>
          Thank you for registering. Our admin team will review your application and get back to you soon.
        </p>
      </RegistrationForm>
    );
  }

  return (
    <RegistrationForm onSubmit={handleSubmit}>
      <FormTitle>Artist Registration</FormTitle>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      
      <Input
        type="text"
        name="name"
        placeholder="Artist/Brand Name"
        value={formData.name}
        onChange={handleChange}
        required
      />
      
      <Input
        type="email"
        name="email"
        placeholder="Email Address"
        value={formData.email}
        onChange={handleChange}
        required
      />
      
      <Input
        type="tel"
        name="phone"
        placeholder="Phone Number"
        value={formData.phone}
        onChange={handleChange}
        required
      />
      
      <TextArea
        name="bio"
        placeholder="Tell us about yourself and your work..."
        value={formData.bio}
        onChange={handleChange}
        required
      />
      
      <SubmitButton type="submit" disabled={loading}>
        {loading ? 'Submitting...' : 'Register as Artist'}
      </SubmitButton>
    </RegistrationForm>
  );
};

export default ArtistRegistration;
