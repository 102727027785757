import React from 'react';
import styled from 'styled-components';
import useAdsense from '../hooks/useAdsense';

const AdWrapper = styled.div`
  width: 100%;
  min-width: 250px;
  display: block;
  
  .adsbygoogle {
    display: block;
    min-width: 250px;
    width: 100%;
    background: #030617;
  }
`;

const GoogleAd2 = () => {
  const adRef = useAdsense();

  return (
    <AdWrapper ref={adRef}>
      <ins
        className="adsbygoogle"
        style={{ display: 'block', minWidth: '250px', width: '100%', background: '#030617' }}
        data-ad-client="ca-pub-2968429081843062"
        data-ad-slot="8703718386"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </AdWrapper>
  );
};

export default GoogleAd2;