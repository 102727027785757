import {React, useEffect} from 'react'
import { setSongs } from '../../features/song/songSlice'
import {useDispatch} from "react-redux"
import { orderBy } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import Nav3 from '../Navs/SideView/Nav3'
import Trending from '../audio/Songs/Trending'
import ViewCounter from '../ViewCounter'
import {Helmet} from 'react-helmet'
import GoogleAd2 from '../GoogleAd2'
import styled from 'styled-components'
import useFirestore from '../../hooks/useFirestore';
import Loader from '../common/Loader';
import ErrorMessage from '../common/ErrorMessage';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #030617;
  overflow-x: hidden;
  width: 100%;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
`;

const SongWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
`;

const SongItem = styled(Link)`
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 1.5rem;
  transition: all 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  
  &:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.08);
  }

  .image-container {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    margin-bottom: 1rem;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.05);
  }

  h3 {
    color: #fff;
    margin: 0 0 0.5rem 0;
    font-size: 1.2rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    color: rgba(255, 255, 255, 0.7);
    margin: 0;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  small {
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.85rem;
    margin-top: 1rem;
  }
`;

const AdContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: center;
`;

function Song() {
    const dispatch = useDispatch();
    const { data: songs, loading, error } = useFirestore('songs', {
        orderBy: ['title', 'asc']
    });

    useEffect(() => {
        if (songs && songs.length > 0) {
            console.log('Dispatching songs to Redux:', songs.length);
            dispatch(setSongs(songs));
        }
    }, [songs, dispatch]);

    if (loading) {
        console.log('Loading songs...');
        return (
            <>
                <Nav3 />
                <Trending />
            </>
        );
    }

    return (
        <>
            <Nav3 />
            <Trending />
        </>
    );
}

export default Song;
