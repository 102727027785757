import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  position: relative;
  height: 288px;
`;

const ImageBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('${(props) => props.imageUrl}') center no-repeat;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(to bottom, rgba(169, 169, 169, 0.5), rgba(0, 0, 0, 0.8));
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 90%;
  z-index: 1;
`;

const Avatar = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
`;

const Title = styled.p`
  color: white;
  margin: 0;
  font-size: 1.1rem;
  font-weight: 500;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
  z-index: 2;
`;

const FeaturedPostCard = ({ recent }) => {
  if (!recent || !recent.image || !recent.title) {
    return (
      <Container>
        <Overlay />
        <Content>
          <Title>Featured Post Data Missing</Title>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <ImageBackground imageUrl={recent.image} />
      <Overlay />
      <Content>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
          <Avatar src={recent.image} alt={recent.title} />
          <Title>{recent.title}</Title>
        </div>
      </Content>
      <StyledLink to={`/musics/${recent.id}`} />
    </Container>
  );
};

FeaturedPostCard.propTypes = {
  recent: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    url: PropTypes.string
  })
};

export default FeaturedPostCard;
