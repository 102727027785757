import React, { useState } from 'react';
import styled from 'styled-components';
import { storage } from '../../firebase.marketplace';
import { ref, getDownloadURL } from 'firebase/storage';
import BillingForm from './BillingForm';

const Card = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 1rem;
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

const ProductTitle = styled.h3`
  color: white;
  margin: 0.5rem 0;
`;

const ProductPrice = styled.p`
  color: #4a90e2;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0.5rem 0;
`;

const ArtistName = styled.p`
  color: #888;
  margin: 0.5rem 0;
`;

const BuyButton = styled.button`
  background: #4a90e2;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #357abd;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #030617;
  padding: 2rem;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
`;

const ProductCard = ({ product, artist }) => {
  const { name, price, imageUrl, description } = product;

  const [showBillingModal, setShowBillingModal] = useState(false);

  const handlePurchase = () => {
    setShowBillingModal(true);
  };

  const handlePurchaseSuccess = (orderId) => {
    setShowBillingModal(false);
    // You can add additional success handling here
  };

  return (
    <Card>
      {imageUrl && <ProductImage src={imageUrl} alt={name} />}
      <ProductTitle>{name}</ProductTitle>
      <ProductPrice>${price}</ProductPrice>
      {artist && <ArtistName>By {artist.displayName || artist.email}</ArtistName>}
      <BuyButton onClick={handlePurchase}>Buy Now</BuyButton>

      {showBillingModal && (
        <Modal onClick={() => setShowBillingModal(false)}>
          <ModalContent onClick={e => e.stopPropagation()}>
            <CloseButton onClick={() => setShowBillingModal(false)}>&times;</CloseButton>
            <BillingForm product={product} artist={artist} onSuccess={handlePurchaseSuccess} />
          </ModalContent>
        </Modal>
      )}
    </Card>
  );
};

export default ProductCard;
