import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaBox, FaSearch, FaFilter, FaEye } from 'react-icons/fa';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import LoadingSpinner from '../common/LoadingSpinner';

const Container = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  color: white;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;

  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background: #4a90e2;
    margin: 1rem auto;
    border-radius: 2px;
  }
`;

const Controls = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SearchBar = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 0.5rem 1rem;

  svg {
    color: #4a90e2;
    margin-right: 0.5rem;
  }
`;

const SearchInput = styled.input`
  flex: 1;
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  outline: none;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;

const FilterButton = styled.button`
  background: rgba(74, 144, 226, 0.1);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(74, 144, 226, 0.2);
  }

  svg {
    color: #4a90e2;
  }
`;

const OrdersGrid = styled.div`
  display: grid;
  gap: 1rem;
`;

const OrderCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
`;

const OrderHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr) auto;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.02);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const OrderInfo = styled.div`
  @media (max-width: 768px) {
    &:nth-child(3), &:nth-child(4) {
      display: none;
    }
  }
`;

const InfoLabel = styled.div`
  color: #888;
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
`;

const InfoValue = styled.div`
  color: white;
  font-size: 0.9rem;
`;

const OrderStatus = styled.div`
  padding: 0.3rem 0.8rem;
  background: ${props => {
    switch (props.status) {
      case 'completed':
        return 'rgba(76, 175, 80, 0.1)';
      case 'processing':
        return 'rgba(74, 144, 226, 0.1)';
      case 'cancelled':
        return 'rgba(244, 67, 54, 0.1)';
      default:
        return 'rgba(255, 255, 255, 0.1)';
    }
  }};
  color: ${props => {
    switch (props.status) {
      case 'completed':
        return '#4CAF50';
      case 'processing':
        return '#4a90e2';
      case 'cancelled':
        return '#f44336';
      default:
        return 'white';
    }
  }};
  border-radius: 4px;
  text-align: center;
  font-size: 0.9rem;
  text-transform: capitalize;
`;

const OrderContent = styled.div`
  padding: 1rem;
`;

const ItemsList = styled.div`
  display: grid;
  gap: 1rem;
`;

const OrderItem = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0.5rem 0;
`;

const ItemImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
`;

const ItemInfo = styled.div`
  flex: 1;
`;

const ItemName = styled.div`
  color: white;
  margin-bottom: 0.3rem;
`;

const ItemPrice = styled.div`
  color: #4a90e2;
  font-size: 0.9rem;
`;

const OrderActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const OrderTotal = styled.div`
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
`;

const ViewDetailsButton = styled(motion.button)`
  background: rgba(74, 144, 226, 0.1);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(74, 144, 226, 0.2);
  }

  svg {
    color: #4a90e2;
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 4rem 2rem;
  color: #888;
`;

const EmptyIcon = styled.div`
  font-size: 3rem;
  color: #4a90e2;
  margin-bottom: 1rem;
`;

const OrderHistory = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersQuery = query(
          collection(db, 'orders'),
          where('userId', '==', 'current-user-id'), // Replace with actual user ID
          orderBy('createdAt', 'desc')
        );
        const ordersSnapshot = await getDocs(ordersQuery);
        const ordersData = ordersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setOrders(ordersData);
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const filteredOrders = orders.filter(order => {
    const matchesSearch = order.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.items.some(item => item.title.toLowerCase().includes(searchTerm.toLowerCase()));
    
    if (filter === 'all') return matchesSearch;
    return matchesSearch && order.status === filter;
  });

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed':
        return '#4CAF50';
      case 'processing':
        return '#4a90e2';
      case 'cancelled':
        return '#f44336';
      default:
        return 'white';
    }
  };

  if (loading) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }

  if (orders.length === 0) {
    return (
      <Container>
        <Header>
          <Title>Order History</Title>
        </Header>
        <EmptyState>
          <EmptyIcon>
            <FaBox />
          </EmptyIcon>
          <p>You haven't placed any orders yet</p>
        </EmptyState>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Title>Order History</Title>
      </Header>

      <Controls>
        <SearchBar>
          <FaSearch />
          <SearchInput
            type="text"
            placeholder="Search orders..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </SearchBar>
        <FilterButton onClick={() => setFilter(filter === 'all' ? 'processing' : 'all')}>
          <FaFilter />
          {filter === 'all' ? 'All Orders' : 'Processing'}
        </FilterButton>
      </Controls>

      <OrdersGrid>
        {filteredOrders.map(order => (
          <OrderCard
            key={order.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <OrderHeader>
              <OrderInfo>
                <InfoLabel>Order ID</InfoLabel>
                <InfoValue>#{order.id.slice(-6)}</InfoValue>
              </OrderInfo>
              <OrderInfo>
                <InfoLabel>Date</InfoLabel>
                <InfoValue>
                  {new Date(order.createdAt.toDate()).toLocaleDateString()}
                </InfoValue>
              </OrderInfo>
              <OrderInfo>
                <InfoLabel>Items</InfoLabel>
                <InfoValue>{order.items.length} items</InfoValue>
              </OrderInfo>
              <OrderInfo>
                <InfoLabel>Total</InfoLabel>
                <InfoValue>${order.total.toFixed(2)}</InfoValue>
              </OrderInfo>
              <OrderStatus status={order.status}>
                {order.status}
              </OrderStatus>
            </OrderHeader>

            <OrderContent>
              <ItemsList>
                {order.items.slice(0, 2).map((item, index) => (
                  <OrderItem key={`${order.id}-${index}`}>
                    <ItemImage src={item.imageUrl} alt={item.title} />
                    <ItemInfo>
                      <ItemName>{item.title}</ItemName>
                      <ItemPrice>${(item.price * item.quantity).toFixed(2)}</ItemPrice>
                    </ItemInfo>
                  </OrderItem>
                ))}
                {order.items.length > 2 && (
                  <div style={{ color: '#888', fontSize: '0.9rem' }}>
                    +{order.items.length - 2} more items
                  </div>
                )}
              </ItemsList>

              <OrderActions>
                <OrderTotal>
                  Total: ${order.total.toFixed(2)}
                </OrderTotal>
                <ViewDetailsButton
                  onClick={() => navigate(`/marketplace/orders/${order.id}`)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <FaEye /> View Details
                </ViewDetailsButton>
              </OrderActions>
            </OrderContent>
          </OrderCard>
        ))}
      </OrdersGrid>
    </Container>
  );
};

export default OrderHistory;
